import { Button, Hidden, Icon } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Task } from '../../api/DomainTypes'
import { AsyncState } from '../../common/Utils'
import {
  NavbarHeight,
  PANEL_MENU,
  SidebarWidth
} from '../../constants/default'
import { useTotalPointsProgress } from '../../hooks/useTotalPointsProgress'
import { RootState } from '../../store'
import { User } from '../../types'
import { pxToRem } from '../../utils'
import MainSidebar from './MainSidebar'
import MobileSidebar from './MobileSidebar'
import MainHeader from '../MainHeader/MainHeader'
import { getMediaContent } from '../../api/chatApi/chatApi'
import chatApi from '../../api/chatApi'
import WestIcon from '@mui/icons-material/West';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import { IconButton } from '@material-ui/core'
const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.background.default,
      // overflowY: 'hidden',
    },
    topNavbar: {
      height: pxToRem(NavbarHeight),
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      padding: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
      background: theme.palette.background.paper
    },
    bodyContainer: {
      height: `calc(100% - ${pxToRem(NavbarHeight)})`,
      position: 'fixed',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        height: `calc(100% - ${pxToRem(NavbarHeight)})`
      }
    },
    contentBox: {
      height: '100%',
      width: (props: Props) =>
        `calc(100% - ${pxToRem(props.hiddenSidebar ? 0 : SidebarWidth)})`,
      overflowX: 'hidden',
      overflowY: 'auto',
      display: (p: Props) => (p.flex ? 'flex' : 'block'),
      flexDirection: (p: Props) => (p.column ? 'column' : 'row'),
      background: theme.palette.background.default,
      [theme.breakpoints.down('xs')]: {
        width: () => '100%'
      }
    },
    topMenu: {
      color: theme.palette.primary.contrastText
    },
    progressBar: {
      position: 'relative',
      display: 'flex',
      alignItems: 'start',
      // border: '1px solid red',
      transform: `translateX(0)`,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 31,
        // width: () => '100%',
        // height: 'calc(100vh - 65px)'
      }
    },
    progressBarActive: {
      transform: 'translateX(0)'
    },
    infoSidebarBtn: {
      display: 'none',
      top: 10,
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block'
      }
      // position: 'absolute',
      // top: '0',
      // right: '-50px',
      // margin: '10px',
    },
    showMobileSidebar: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block'
      }
    }
  }
})

type Props = {
  children: ReactNode
  title?: string
  desktopHeader?: ReactNode
  mobileHeader?: ReactNode
  flex?: boolean
  column?: boolean
  onClickTooltip?: () => void
  hiddenSidebar?: boolean
  patientModel?: any
  showComponentHelpFilled?: boolean
}

export default function MainLayout(props: Props) {
  const [open, setOpen] = React.useState(false)
  const [contentBoxHeight, setContentBoxHeight] = React.useState(0);
  const contentBoxRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (contentBoxRef.current) {
      setContentBoxHeight(contentBoxRef.current.offsetHeight);
    }
  }, []);

  const handleMenuOpen = () => {
    setOpen(!open)
  }
  const [mediaLink, setMediaLink] = useState('')

  const task = useSelector<RootState, AsyncState<Task> | undefined>(state => {
    return state.chat.currentTaskState;
  });

  useEffect(() => {
    let isMounted = true;

    const fetchMediaContent = async () => {
      if (task?.value?.patient.photo && isMounted) {
        try {
          const res = await getMediaContent(task.value.patient.photo);
          setMediaLink(res.data.data_base64);
        } catch (error) {
          console.error('Ошибка при получении медиаконтента:', error);
        }
      }
    };

    fetchMediaContent();

    return () => {
      isMounted = false;
    };
  }, []);


  const patient = task?.value?.patient
  const userInfo: User = {
    avatar: mediaLink,
    name: `${patient?.firstName ? patient?.firstName : ''} ${patient?.secondName ? patient?.secondName : ''
      }`,
    status: 1
  }

  const classes = useStyles(props)

  const [successPoints, penaltyPoints] = useTotalPointsProgress()

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* TITLE DESKTOP ONLY */}
      <Hidden xsDown>
        <MainHeader
          title={props.title}
          onClickTooltip={props.onClickTooltip}
          hiddenSidebar={props.hiddenSidebar}
          showComponentHelpFilled />

        {/* <AppBarDesktop
          title={props.title}
          onClickTooltip={props.onClickTooltip}
          hiddenSidebar={props.hiddenSidebar}
        /> */}
        {/* <div className={classes.topNavbar}>{props.desktopHeader ? props.desktopHeader : <ChatPagesMenu />}</div> */}
      </Hidden>

      {/* TITLE MOBILE ONLY */}
      <Hidden smUp>
        <MainHeader
          title={props.title}
          onClickTooltip={props.onClickTooltip}
          hiddenSidebar={props.hiddenSidebar}
          showComponentHelpFilled />
        {/* <div className={classes.topNavbar}>
          {props.mobileHeader ? (
            props.mobileHeader
          ) : (
            <ChatPagesMenuMobile menuItems={CHAT_MENU_ITEMS} />
          )}
        </div> */}
      </Hidden>

      <div className={classes.bodyContainer}>
        {/* SIDEBAR (DESKTOP ONLY) */}
        {!props.hiddenSidebar && (
          <>
            <Hidden xsDown>
              <MainSidebar
                menuItems={PANEL_MENU}
                user={userInfo}
                notes={[]}
                anamnesisProgress={successPoints}
                anamnesisErrors={penaltyPoints}
                mini={false}
                patientModel={props.patientModel}
              />
            </Hidden>
          </>
        )}

        {/* SIDEBAR (MOBILE ONLY) */}
        <div className={classes.progressBar}>
          {open && !props.hiddenSidebar && (
            <Hidden smUp>
              <MobileSidebar
                menuItems={PANEL_MENU}
                user={userInfo}
                notes={[]}
                anamnesisProgress={successPoints}
                anamnesisErrors={penaltyPoints}
                mini={false}
                patientModel={props.patientModel}
              />
            </Hidden>
          )}

          {!props.hiddenSidebar && (
            <IconButton
              onClick={handleMenuOpen}
              className={classes.infoSidebarBtn}
            >
              {open ?
                // <KeyboardArrowLeftIcon fontSize='large' color='primary' />
                // <span>Право</span>
                <KeyboardBackspaceIcon
                  style={{ borderRadius: '50%', background: 'white', height: 40, width: 40, padding: 10 }}
                  fontSize={'medium'}
                  color={'primary'}
                />
                :
                // <KeyboardArrowRightIcon fontSize='large' color='primary' />
                //<Button >
                <EastIcon
                  style={{ borderRadius: '50%', background: 'white', height: 40, width: 40, padding: 10 }}
                  fontSize={'medium'}
                  color={'primary'}
                />
                //</Button>
                // <span>Лево</span>
              }

            </IconButton>
          )}
        </div>

        {/* CONTENT (both Desktop and Mobile) */}
        <div ref={contentBoxRef} className={classes.contentBox}>{props.children}</div>
      </div>
    </div>
  )
}
function async(photo: string | undefined) {
  throw new Error('Function not implemented.')
}

