import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import parse, { DOMNode, Element, Text as TextNode } from 'html-react-parser';
import chatApi from '../../../api/chatApi';
import {
  getTestingstageTaskOnTask,
  setLegendOptionsBackUsertaskproceed,
  getLegendOptionsBackUsertaskproceed
} from '../../../api/chatApi/chatApi';
import RatingDialog from '../../Chat/RatingDialog';
import CustomButton from "../../common/CustomButton";
import MainLayout from "../../Layout/MainLayout";
import {
  Box,
  Divider,
  Modal,
  Backdrop,
  Fade,
  Typography,
  IconButton,
  CircularProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useTestingStage from "../../../hooks/testingTask/useTestingStage";
import DeadRat1 from '../../../assets/img/legend/DeadRat/DeadRat1.jpg';
import DeadRat2 from '../../../assets/img/legend/DeadRat/DeadRat2.jpg';
import DeadRat3 from '../../../assets/img/legend/DeadRat/DeadRat3.jpg';
import DeadRat4 from '../../../assets/img/legend/DeadRat/DeadRat4.jpg';
import DeadRat5 from '../../../assets/img/legend/DeadRat/DeadRat5.jpg';
import DeadRat26 from '../../../assets/img/legend/DeadRat/DeadRat26.jpg';
import Homunculus1 from '../../../assets/img/legend/DeadRat/homunculus1.png';
import Check from '../../../assets/img/check.png'
import Cross from '../../../assets/img/remove.png'
import Protocol from '../../../assets/img/protocol.jpg'

interface FileObject {
  file?: File;
  content: string;
  name: string;
  order: number;
  id?: number;
}


export default function LegendPage() {
  const [openRating, setOpenRating] = useState(false);
  const [isLegend, setIsLegend] = useState('');
  const [stages, setStages] = useState<any[]>([]);
  const { routhPathCode, activeStage } = useNextStagePath(stages);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [title, setTitle] = useState('');
  const [currentTaskId, setCurrentTaskId] = useState<number | undefined>(undefined);
  const [legendOrder, setLegendOrder] = useState<number | undefined>(undefined);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [currentStage, setCurrentStage] = useState<number | undefined>(undefined);
  const [currentContent, setCurrentContent] = useState<string>('');
  const [files, setFiles] = useState<FileObject[]>([]);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const testingStage = useTestingStage();
  const location = useLocation();
  const history = useHistory();
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState('');
  const [currentLegendOptionId, setCurrentLegendOptionId] = useState<number | null>(null);
  const [currentModalQuestion, setCurrentModalQuestion] = useState<string>("");
  const [isAnswerTrue, setIsAnswerTrue] = useState(false)
  const [isAnswerSend, setIsAnswerSend] = useState(false)
  const [isShowProtocol, setIsShowProtocol] = useState(false)

  const handleNonHiddenImgClickGlobal = useCallback((src: string) => {
    setSelectedImage(src);
    setOpenModal(true);
  }, []);


  /**
   * Функция парсит HTML-строку и:
   * 1) Заменяет в текстовых узлах вхождения фраз из clickableWords на кликабельные <span> с обработчиками.
   * 2) Если узел – тег <img> и его src совпадает с одним из значений из imageToHide, возвращает <div style={{visibility:'hidden'}} /> То есть скрываем это изображение, чтобы потом его открыть по клику на нужной строке
   */
  function parseContentWithClickableWords(
    html: string,
    clickableWords: { word: string; onClick: () => void }[],
    imageToHide: string[],
    handleNonHiddenImgClick
  ) {
    return parse(html, {
      replace: (domNode: DOMNode) => {
        if (domNode.type === 'tag') {
          const elem = domNode as Element;
          if (elem.name === 'img') {
            const srcAttr = elem.attribs?.src || '';
            if (imageToHide.some(src => src?.trim() === srcAttr?.trim())) {
              return <span style={{ visibility: 'hidden' }} />;
            } else {
              return (
                <span>
                  <img
                    src={srcAttr}
                    style={{ maxWidth: '30%', cursor: 'pointer' }}
                    onClick={() => handleNonHiddenImgClick(srcAttr)}
                  />
                </span>
              );
            }
          }
        }
        if (domNode.type === 'text') {
          const textNode = domNode as TextNode;
          const text = textNode.data;
          if (!clickableWords.some(cw => text.includes(cw.word))) {
            return undefined;
          }
          const nodes: React.ReactNode[] = [];
          let currentIndex = 0;
          while (currentIndex < text.length) {
            let nextMatchIndex = Infinity;
            let matchedWord: { word: string; onClick: () => void } | null = null;
            clickableWords.forEach(cw => {
              const idx = text.indexOf(cw.word, currentIndex);
              if (idx !== -1 && idx < nextMatchIndex) {
                nextMatchIndex = idx;
                matchedWord = cw;
              }
            });
            if (nextMatchIndex === Infinity || !matchedWord) {
              nodes.push(text.substring(currentIndex));
              break;
            }
            if (nextMatchIndex > currentIndex) {
              nodes.push(text.substring(currentIndex, nextMatchIndex));
            }
            nodes.push(
              <Box
                component="span"
                onClick={matchedWord.onClick}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: '#d6ebff',
                  padding: '0.2rem 0.4rem',
                  borderRadius: 1,
                  transition: 'background-color 0.4s',
                  '&:hover': {
                    backgroundColor: '#1F79D2',
                    color: 'white'
                  },
                }}
              >
                {matchedWord.word}
              </Box>
            );
            currentIndex = nextMatchIndex + matchedWord.word.length;
          }
          return <React.Fragment>{nodes}</React.Fragment>;
        }
        return undefined;
      }
    });
  }


  const additionalPhrasesForImage = [
    "Отвод жидких бытовых отходов",
    "твердых бытовых отходов",
    "Обработка продуктов",
    "Мытье посуды в горячем цехе",
    "Сбор пищевых отходов",
    "Раздача готовых блюд, сбор грязной посуды",
    "Обработка сырья и готовой продукции",
    "Состояние разделочных досок и ножей",
    "Правила мытья посуды не соблюдаются",
    "Холодильное оборудование представлено",
    "Внешний вид фаянсовой посуды для приема пищи"
  ];

  const [additionalImageSources, setAdditionalImageSources] = useState<Record<string, string | null>>(
    additionalPhrasesForImage.reduce((acc, phrase) => {
      acc[phrase] = null;
      return acc;
    }, {} as Record<string, string | null>)
  );

  // Дополнительные фразы (вопросы) с их данными: id и текст вопроса.
  const additionalQuestions: Record<string, { id: number; question: string }> = {
    "Югводоканал": {
      id: 1,
      question: "Какое действие необходимо совершить после ознакомления с данной информацией?"
    },
    "Персонал не обеспечен санитарной одеждой": {
      id: 2,
      question: "Какую дополнительную информацию относительно персонала кухни необходимо запросить?"
    },
    "Персонал в качестве санитарной одежды использует фартуки и косынки": {
      id: 4,
      question: "Какую дополнительную информацию относительно персонала необходимо запросить?"
    },
    "Дети младшего возраста данные блюда и продукты не употребляли.": {
      id: 6,
      question: "Какие пробы и лабораторные испытания должны быть проведены после ознакомления с данной информацией?"
    },
    "Какие действия Вы должны осуществить в целях локализации эпидемического очага?": {
      id: 8,
      question: "Какие действия Вы должны осуществить в целях локализации эпидемического очага?"
    }
  };

  // Обработчики клика для якорей, открывающих модальное окно с вопросом.
  const handleQuestionClick = async (phrase: string) => {
    setIsShowProtocol(false)
    const answerResult = await getLegendOptionsBackUsertaskproceed()
    const data = phrase in additionalQuestions ? additionalQuestions[phrase] : '';
    const answer = answerResult.data.legend_options_back_usertaskproceed?.find(el => el.legend_options_back_id === data.id)

    if (answer && answer.correct === true && answer.value_param !== 'запросить протоколы лабораторных испытаний воды') {
      setIsAnswerTrue(true)
      setIsAnswerSend(true)
    } else if (answer && answer.correct === true && answer.value_param === 'запросить протоколы лабораторных испытаний воды') {
      setIsShowProtocol(true)
    } else if (answer && answer.correct === false && answer.value_param !== null) {
      setIsAnswerSend(true)
      setIsAnswerTrue(false)
    } else {
      setIsAnswerSend(false)
    }
    setCurrentLegendOptionId(data.id);
    setCurrentModalQuestion(data.question);
    setOpenQuestionModal(true);
  };

  // Обработчики клика для якорей, открывающих модалки с изображениями – все используют общий модал.
  const handleImageClick = useCallback((phrase: string, src: string | null) => {
    if (src) {
      setSelectedImage(src);
      setOpenModal(true);
    }
  }, []);

  const combinedClickableWords = useMemo(() => {
    return [
      { word: 'Югводоканал', onClick: () => handleQuestionClick('Югводоканал') },
      { word: 'Персонал не обеспечен санитарной одеждой', onClick: () => handleQuestionClick('Персонал не обеспечен санитарной одеждой') },
      { word: 'Персонал в качестве санитарной одежды использует фартуки и косынки', onClick: () => handleQuestionClick('Персонал в качестве санитарной одежды использует фартуки и косынки') },
      { word: 'Дети младшего возраста данные блюда и продукты не употребляли.', onClick: () => handleQuestionClick('Дети младшего возраста данные блюда и продукты не употребляли.') },
      { word: 'Какие действия Вы должны осуществить в целях локализации эпидемического очага?', onClick: () => handleQuestionClick('Какие действия Вы должны осуществить в целях локализации эпидемического очага?') },
      ...additionalPhrasesForImage.map(phrase => ({
        word: phrase,
        onClick: () => handleImageClick(phrase, additionalImageSources[phrase])
      }))
    ];
  }, [handleQuestionClick, handleImageClick, additionalImageSources]);

  useEffect(() => {
    fetchStages();
  }, []);

  async function fetchStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
      setCurrentStage(result.data.testingstage_task_on_task[0]);
    } catch (error) {
      console.warn("Ошибка при загрузке этапов:", error);
    }
  }

  const [legendOptionId, setLegendOptionID] = useState('')

  // Загрузка контента
  useEffect(() => {
    const loadContent = async () => {
      let legendIdFromState = location.state?.legendId;
      if (!legendIdFromState && stages) {
        legendIdFromState = stages.find((stage) => stage.routePath === '/legend')?.legend_id;
        if (legendIdFromState < 0) {
          console.warn('Legend ID не найден');
          return;
        }
      }
      setLegendOptionID(legendIdFromState)
      setIsLoadingContent(true);
      setCurrentContent('');
      await getUnfinishedTask();
      try {
        if (currentTaskId) {
          const result = await chatApi.getLegendOption(currentTaskId);
          if (result?.result) {
            const legendData = result.result.map((legend: any) => ({
              id: legend.id,
              content: legend.htmlText,
              name: legend.nameStage,
              order: legend.sort,
            }));
            setFiles(legendData);
            const matchingContent = legendData.find(
              (file: FileObject) => file.id === legendIdFromState
            )?.content || '';
            setCurrentContent(matchingContent);
          }
        }
      } catch (error) {
        console.warn('Ошибка загрузки данных:', error);
      } finally {
        setIsLoadingContent(false);
      }
    };
    loadContent();
  }, [location.key, stages, currentTaskId]);

  async function getUnfinishedTask() {
    try {
      const currTask = await chatApi.unfinishedTask();
      setIsLegend(currTask.result.task.patientModel.legend);
      setCurrentTaskId(currTask.result.task.id);
    } catch (error) {
      console.warn(error);
    }
  }

  // Отслеживаем изменение размера экрана
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 600);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Получение настроек диалога
  useEffect(() => {
    const getDialogStatus = async () => {
      const response = await chatApi.getSettingsPiloting();
      const statusValue = response.result[0].value;
      setStatus(statusValue === 'true');
    };
    getDialogStatus();
  }, []);

  async function getHistoryStage() {
    setIsLoadingBtn(true);
    try {
      const res = await chatApi.getTestingStageHistory();
      if (res.result.length > 0) {
        setLegendOrder(res.result[res.result.length - 1].activeStageId);
      } else {
        setLegendOrder(1);
      }
      setIsLoadingBtn(false);
    } catch (error) {
      console.warn(error);
      setIsLoadingBtn(false);
    }
  }

  async function setHistoryStage(testingStage: number) {
    try {
      await chatApi.setTestingStageHistory(testingStage);
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    getHistoryStage();
  }, []);

  async function goToNextStage() {
    if (!stages || stages.length === 0) {
      console.warn('Этапы отсутствуют.');
      return;
    }
    const currentPath = location.pathname;
    let currentStageToGoNext;
    const resultStage = stages.filter(stage => stage.routePath === currentPath);
    if (resultStage && resultStage.length > 0) {
      currentStageToGoNext = resultStage.find(
        stage => stage.legend_id === location.state?.legendId
      );
    } else {
      currentStageToGoNext = resultStage;
    }

    const currentActiveStage = currentStageToGoNext.activeStage;

    const sortedStages = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage);
    const nextStage = sortedStages[0];
    if (!nextStage) {
      console.warn('Нет доступных следующих стадий.');
      return;
    }
    setCurrentStage(nextStage);
    setHistoryStage(currentActiveStage);
    if (nextStage.routePath === '/legend') {
      history.push({
        pathname: nextStage.routePath,
        state: { legendId: nextStage.legend_id },
      });
    } else {
      history.push(nextStage.routePath);
    }
  }

  useEffect(() => {
    if (location.pathname === '/legend') {
      const legendIdFromState = location.state?.legendId;
      const currentLegendId = stages?.find(stage => stage.routePath === '/legend')?.legend_id;
      if (!legendIdFromState && currentLegendId) {
        history.replace({
          pathname: location.pathname,
          state: { legendId: currentLegendId },
        });
      }
    }
  }, [location, stages, history]);

  // Извлекаем картинки для дополнительных фраз
  useEffect(() => {
    if (!currentContent) return;
    additionalPhrasesForImage.forEach(phrase => {
      const regex = new RegExp(phrase + "[\\s\\S]*?<img[^>]+src=\"([^\"]+)\"", "i");
      const match = currentContent.match(regex);
      if (match && match[1]) {
        setAdditionalImageSources(prev => ({
          ...prev,
          [phrase]: match[1]
        }));
      }
    });
  }, [currentContent]);


  const handleSubmitAnswer = async () => {
    setIsShowProtocol(false)
    try {
      const answerToSend = {
        legend_options_id: Number(legendOptionId),
        param: [
          {
            gigachat: true,
            legend_options_back_id: currentLegendOptionId,
            value_param: questionAnswer,
          }
        ]
      };
      await setLegendOptionsBackUsertaskproceed(answerToSend);
      const answerResult = await getLegendOptionsBackUsertaskproceed()
      const answer = answerResult.data.legend_options_back_usertaskproceed?.find(el => el.legend_options_back_id === currentLegendOptionId)

      if (answer.correct === true && answer.value_param !== 'запросить протоколы лабораторных испытаний воды') {
        setIsAnswerTrue(true)
      } else if (answer.correct === true && answer.value_param === 'запросить протоколы лабораторных испытаний воды') {
        setIsShowProtocol(true)
      } else {
        setIsAnswerTrue(false)
      }
      setQuestionAnswer('');
      setCurrentLegendOptionId(null);
      setIsAnswerSend(true)
    } catch (error) {
      console.warn('Ошибка при отправке ответа:', error);
    }
  };

  const experimentsDeadRat1 = [
    {
      title: 'Опыт А',
      description: `Лабораторное животное (мышь, крыса) помещено в небольшую барокамеру. В течение 2–3 мин из нее откачивают воздух, понижая в ней атмосферное давление примерно до 23 кПа. Через 0,5–1 мин пребывания в разреженной атмосфере животное проявляет признаки беспокойства: перебирает лапками, почёсывает мордочку, бегает по барокамере; ещё через 2–3 мин развиваются клонико–тонические судороги, мочеиспускание, животное лежит на боку, видны редкие дыхательные движения грудной клетки и брюшной стенки (признаки терминального дыхания «гаспинг»). Вскоре происходит полная остановка дыхания, животное погибает. Продолжительность жизни животного в разреженной атмосфере составляет, в среднем, 3 мин.`,
      image: DeadRat1
    },
    {
      title: 'Опыт Б',
      description: `Из барокамеры откачивают воздух до давления 4 кПа, после чего заполняют барокамеру чистым кислородом до нормального атмосферного давления. Приоткрыв дверцу барокамеры, помещают туда экспериментальное животное и немедленно вновь герметизируют камеру. В дальнейшем поступают так же, как и в опыте А, понижая атмосферное давление в камере примерно до 23 кПа при нормальном парциальном давлении кислорода в воздухе. Наблюдают за состоянием животного. Вначале у него возникает ориентировочная реакция; затем животное успокаивается. Каких-либо патологических явлений у него не развивается. Через 10 мин опыт прекращают и извлекают животное из камеры.`,
      image: DeadRat2
    },
    {
      title: 'Опыт В',
      description: `Барокамеру заполняют газовой смесью, состоящей из 95% азота и 5% кислорода (парциальное давление кислорода в такой смеси равно примерно 5 кПа) при нормальном атмосферном давлении. Помещают туда экспериментальное животное и продолжают пропускать струю указанной газовой смеси. Через 8 мин у животного развиваются судороги, остановка дыхания и оно погибает.`,
      image: DeadRat3
    },
    {
      title: 'Опыт Г',
      description: `Эксперимент проводится на 3 животных (мышь, крыса). Животное №1 подвергают умеренной физической нагрузке (плавание в аквариуме, температура воды около 30°C). Через 5 мин в тот же аквариум (для контроля нахождения в воде аквариума) помещают животное №2. Спустя 5 сек обоих животных извлекают из воды, помещают в барокамеру вместе с животным №3 (интактным) и откачивают из барокамеры воздух аналогично опыту А, понижая в ней атмосферное давление примерно до 23 кПа. Интактное животное мышь погибает через 3–4 мин, животное №2, находившееся в аквариуме пять секунд, спустя 5-6 мин, Животное №1 выдерживает пребывание в разреженной атмосфере барокамеры в течение 15 мин или более, после чего опыт прекращают и извлечённое из барокамеры животное остается живым.`,
      image: DeadRat4
    },
    {
      title: 'Опыт Д',
      description: `Эксперимент проводится на 3 животных. Животное №1 наркотизируют (например, внутрибрюшинным введением уретана). Это животное используют в опыте после развития у него глубокого наркоза. Животному №2 за 10 мин до опыта вводят стимулятор ЦНС (например, фенамин). Животное №3 служит контролем. Всех трёх животных помещают в барокамеру и откачивают воздух аналогично опыту А до атмосферного давления примерно в 23 кПа. Животное №2 (предварительное введение психостимулятора) обычно погибает на второй минуте пребывания в барокамере, в которой РАТМ равно 23 кПа, животное №3 (контрольное) на четвёртой - пятой минуте; животное №1 (наркотизированное уретаном) выдерживает, в среднем, около 15 мин (и более) гипобарической гипоксии. После этого его извлекают из барокамеры. У этого животного после пробуждения от наркоза не обнаруживается признаков заметного нарушения жизнедеятельности.`,
      image: DeadRat5
    },
  ];

  const experimentsDeadRat2 = [
    {
      title: 'Опыт',
      description: `С целью моделирования гемолитической анемии мышам внутрибрюшинно ввели раствор фенилгидразина. Через 50 мин после введения фенилгидразина в крови животных выявлены эритропения, лейкопения, свободные формы гемоглобина и метгемоглобина.`,
      image: DeadRat26
    },
  ];

  const experimentsHomunculus1 = [
    {
      image: Homunculus1
    },
  ];

  return (
    <MainLayout title={title}>
      {openRating && <RatingDialog open={openRating} onSubmit={() => setOpenRating(false)} />}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
        {isLoadingContent && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 100 }}>
            <CircularProgress />
          </div>
        )}
        {isLegend === 'dead_rat' && <ExperimentsDisplay experiments={experimentsDeadRat1} />}
        {isLegend === 'dead_rat_2' && <ExperimentsDisplay experiments={experimentsDeadRat2} />}
        {isLegend === 'homunculus1' && <ExperimentsDisplay experiments={experimentsHomunculus1} />}
        {!isLoadingContent && currentContent && (
          <div style={{ padding: '0 10px' }}>
            {parseContentWithClickableWords(
              currentContent,
              combinedClickableWords,
              [
                ...additionalPhrasesForImage.map(phrase => {
                  return additionalImageSources[phrase] || "";
                }).filter(Boolean)
              ],
              handleNonHiddenImgClickGlobal
            )}
          </div>
        )}
        <div style={{marginTop: 30}}>
          <CustomButton variant="contained" color="primary" disableElevation onClick={goToNextStage}>
            {testingStage >= activeStage ? "Следующий этап" : "Закончить этап"}
          </CustomButton>
        </div>
      </div>

      {/* Модалка для вопросов */}
      <Modal
        open={openQuestionModal}
        onClose={() => { setOpenQuestionModal(false); setQuestionAnswer(''); setCurrentLegendOptionId(null); }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openQuestionModal}>
          <Box sx={{
            fontSize: '18px',
            position: 'absolute', top: '50%', left: '50%',
            transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
            boxShadow: 24, p: 4, width: isAnswerSend ? '30%' : '90%', maxWidth: 900, height: 'auto', maxHeight: 300, borderRadius: 2,
            border: isAnswerSend ? isAnswerTrue ? '2px solid #78ce56' : '2px solid red' : 'none'
          }}>
            <IconButton
              aria-label="close"
              onClick={() => setOpenQuestionModal(false)}
              sx={{
                position: 'absolute',
                right: '1rem',
                top: '1rem',
                p: 0
              }}
            >
              <CloseIcon />
            </IconButton>
            {!isAnswerSend ?
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '18px', textAlign: 'center' }} variant="body1" gutterBottom>
                  {currentModalQuestion}
                </Typography>
                <textarea
                  value={questionAnswer}
                  rows={3}
                  onChange={(e) => setQuestionAnswer(e.target.value)}
                  style={{ width: '100%', padding: '10px', marginBottom: '10px', fontSize: '18px', marginTop: 12, borderRadius: '8px', resize: 'none' }}
                  autoFocus />
                <Box sx={{ marginTop: '1rem' }}>
                  <CustomButton variant="contained" color="primary" onClick={handleSubmitAnswer}>
                    Отправить
                  </CustomButton>
                </Box>
              </Box> :
              !isShowProtocol ? <>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                  {isAnswerTrue ? 'Вы дали правильный ответ' : "Вы дали неверный ответ"}
                </Typography>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'row', alignItems: 'center', marginTop: '1.3rem' }}>
                  {isAnswerTrue ? <img src={Check} alt='check' style={{ width: 40, height: 40 }} /> : <img src={Cross} alt='cross' style={{ width: 40, height: 40 }} />}
                </div>
                <Box sx={{ marginTop: isAnswerSend ? '2rem' : '5rem' }}>
                  <CustomButton variant="contained" color="primary" onClick={() => setOpenQuestionModal(false)}>
                    Ок
                  </CustomButton>
                </Box>
              </> : <div
                style={{
                  position: 'fixed',
                  top: '100%', left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '60vw',
                  height: '80vh',
                  overflowY: 'auto',
                  background: 'black',
                  display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
                }}
              >
                <img src={Protocol} style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }} alt='Protocol' />
              </div>
            }
          </Box>
        </Fade>
      </Modal>
      {/* Общая модалка для увеличенного изображения */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: '2.5rem',
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflow: 'auto',
              borderRadius: 2,
              '@media (max-width:600px)': {
                width: '95%',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                p: 2
              }
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => setOpenModal(false)}
              sx={{
                position: 'absolute',
                right: '1rem',
                top: '1rem',
                p: 0
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={selectedImage!}
              alt="Увеличенное изображение"
              style={{
                display: 'block',
                margin: '0 auto',
                maxWidth: '100%',
                maxHeight: '800px',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </MainLayout >
  );
}


function useNextStagePath(stages: any[]) {
  const location = useLocation();
  const currentPath = location.pathname;
  return useMemo(() => {
    if (!stages || stages.length === 0) {
      return { routePath: '', routhPathCode: '', activeStage: '' };
    }
    const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
    const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);
    if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
      return {
        routePath: sortedStages[currentIndex + 1].routePath,
        routhPathCode: sortedStages[currentIndex + 1].routhPathCode,
        activeStage: sortedStages[currentIndex + 1].activeStage
      };
    }
    return { routePath: '', routhPathCode: '', activeStage: '' };
  }, [stages, currentPath]);
}

/**
 *  Компонент для отображения экспериментов.
 */
function ExperimentsDisplay({ experiments }: { experiments: any[] }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [openModalLocal, setOpenModalLocal] = useState(false);
  const [selectedImageLocal, setSelectedImageLocal] = useState<string | null>(null);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 600);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ marginBottom: '50px', width: '95%' }}>
      {experiments.map((experiment, index) => (
        <div key={index}>
          {index > 0 && <Divider />}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px 0',
            flexDirection: isMobile ? 'column' : 'row'
          }}>
            {experiment.title && (
              <div style={{
                marginLeft: isMobile ? '0' : '20px',
                fontSize: '18px',
                width: isMobile ? '100%' : '55%',
                textAlign: isMobile ? 'center' : 'left'
              }}>
                <Typography variant="h6" gutterBottom>{experiment.title}</Typography>
                <Typography variant="body1">{experiment.description}</Typography>
              </div>
            )}
            {experiment.image && (
              <div style={{ flexShrink: 0, maxWidth: '800px', maxHeight: '500px', overflow: 'hidden' }}>
                <img
                  src={experiment.image}
                  alt={experiment.title}
                  style={{
                    width: '100%', height: 'auto', objectFit: 'contain',
                    cursor: isMobile ? 'default' : 'pointer'
                  }}
                  onClick={() => {
                    if (!isMobile) {
                      setSelectedImageLocal(experiment.image);
                      setOpenModalLocal(true);
                    }
                  }}
                />
              </div>
            )}
          </div>
          <Modal
            open={openModalLocal}
            onClose={() => setOpenModalLocal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={openModalLocal}>
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                maxWidth: '85%',
                maxHeight: '85%',
                width: '80%',
                height: 'auto',
                overflow: 'hidden',
                padding: '50px',
                '@media (max-width:600px)': {
                  width: '95%',
                  height: '95%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  p: 2
                }
              }}>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenModalLocal(false)}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
                <img
                  src={selectedImageLocal!}
                  alt="Увеличенное изображение"
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxHeight: '100%'
                  }}
                />
              </Box>
            </Fade>
          </Modal>
        </div>
      ))}
    </div>
  );
}
