import { createStyles, makeStyles } from '@material-ui/core';

interface getAssessmentProfessionalStandardInterface {
  id: string,
  medicine: string,
  typeofdifficultlevel: string,
  universitylevel: string,
  name: string,
  point: string,
}
interface ChampionMobileElement {
  ratingElement: getAssessmentProfessionalStandardInterface
}
export default function SpecificMobileElement ({ratingElement}: ChampionMobileElement) {

  const useStyles = makeStyles((theme) =>
    createStyles({
      ratingMobileElementContainer: {
        border: '1px solid gray',
        borderRadius: 12,
        padding: 10,
        margin: '15px auto',
        maxWidth: 1045,
        width: '100%'
      },
      titleChapter: {
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      },
      textWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 10
      },
      descriptionChapter: {
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      }
    })
  );

  const style = useStyles()
  return (
    <div className={style.ratingMobileElementContainer}>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Номер: </p>
        <span className={style.descriptionChapter}>{ratingElement.id}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}> Раздел медицины: </p>
        <span className={style.descriptionChapter}>{ratingElement.medicine}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Уровень сложности: </p>
        <span className={style.descriptionChapter}>{ratingElement.typeofdifficultlevel}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Академический уровень: </p>
        <span className={style.descriptionChapter}>{ratingElement.universitylevel}</span>
      </div>
      <div style={{margin: '4px auto'}}>
        <p className={style.titleChapter}>Пациент: </p>
        <span className={style.descriptionChapter}>{ratingElement.name}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Очки: </p>
        <span className={style.descriptionChapter}>{ratingElement.point}</span>
      </div>
    </div>
  )
}