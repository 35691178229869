import { createStyles, makeStyles } from '@material-ui/core';

interface getAssessmentProfessionalStandardInterface {
  trainer_name: string,
  organization_name: string,
  student_speciality: string,
  procent: string,
  point: string,
}
interface ChampionMobileElement {
  ratingElement: getAssessmentProfessionalStandardInterface
}
export default function DepersonalizedMobileElement ({ratingElement}: ChampionMobileElement) {
  // trainer_name
  // organization_name
  // student_speciality
  // procent
  // point
  //
  // Тренажер
  // Организация
  // Специальность
  // Процент
  // Очки
  const useStyles = makeStyles((theme) =>
    createStyles({
      ratingMobileElementContainer: {
        border: '1px solid gray',
        borderRadius: 12,
        padding: 10,
        margin: '15px auto',
        maxWidth: 1045,
        width: '100%'
      },
      titleChapter: {
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      },
      textWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 10
      },
      descriptionChapter: {
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      }
    })
  );
  const style = useStyles()
  return (
    <div className={style.ratingMobileElementContainer}>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Тренажер: </p>
        <span className={style.descriptionChapter}>{ratingElement.trainer_name}</span>
      </div>
      <div style={{margin: '4px auto'}}>
        <p className={style.titleChapter}>Организация: </p>
        <span className={style.descriptionChapter}>{ratingElement.organization_name}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Специальность: </p>
        <span className={style.descriptionChapter}>{ratingElement.student_speciality}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Процент: </p>
        <span className={style.descriptionChapter}>{ratingElement.procent}%</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Очки: </p>
        <span className={style.descriptionChapter}>{ratingElement.point}</span>
      </div>
    </div>
  )
}