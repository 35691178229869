import { makeStyles, Theme, createStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import chatApi from '../../api/chatApi'
import { DifficultLevel } from '../../api/DomainTypes'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import ChooserAcademicLevel from '../../components/Chat/ChooserAcademicLevel'
import ChooseTaskStepper from '../../components/Chat/ChooseTaskStepper'
import CustomHeader1 from '../../components/common/CustomHeader1'
import MainLayout from '../../components/Layout/MainLayout'
import MainHeader from '../../components/MainHeader/MainHeader'
import { EXAM_ACADEMIC_LEVELS } from '../../constants/default'
import { RoutePath } from '../../types'
import NewTaskContentLayout from './NewTaskContentLayout'

// const difficultLevelsDemo: Array<DifficultLevel> = [
const difficultLevelsDemo: Array<any> = [
  {
    active: false,
    code: 'N1',
    coefficient: 5,
    id: 1,
    name: 'hello',
    image: EXAM_ACADEMIC_LEVELS.five.imageDisabled,
    imageHover: EXAM_ACADEMIC_LEVELS.five.image,
    stepText: 'III'
  },
  {
    active: false,
    code: 'N2',
    coefficient: 4,
    id: 3,
    name: 'hello',
    image: EXAM_ACADEMIC_LEVELS.four.imageDisabled,
    imageHover: EXAM_ACADEMIC_LEVELS.four.image,
    stepText: 'IV'
  },
  {
    active: true,
    code: 'N3',
    coefficient: 3,
    id: 2,
    name: 'hello',
    image: EXAM_ACADEMIC_LEVELS.three.imageDisabled,
    imageHover: EXAM_ACADEMIC_LEVELS.three.image,
    stepText: 'V'
  },
  {
    active: true,
    code: 'N4',
    coefficient: 3,
    id: 4,
    name: 'hello',
    image: EXAM_ACADEMIC_LEVELS.three.imageDisabled,
    imageHover: EXAM_ACADEMIC_LEVELS.three.image,
    stepText: 'VI'
  }
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newChatTitle: {
      maxWidth: '1060px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto'

    },
    newChatTitleOrg: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '500px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px',
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    organisationBlock: {
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
    }
  })
)

interface Props {
  taskName?: string
  noAcademLevel?: boolean
  showComponentHelpFilled?: boolean
  userOrganizations?: string[]
}

export default function ChooseAcademLevelPage(props: Props) {
  const history = useHistory()
  const classes = useStyles()
  // const [universityLevel, setUniversityLevel] = useState();

  React.useEffect(() => {
    if (props.noAcademLevel) {
      history.push(`${RoutePath.newChat}?universityLevelId=-1`)
    }
  })

  const handleClickLevel = (type: DifficultLevel) => {
    history.push(`${RoutePath.newChat}?universityLevelId=${type.id}`)
  }

  // Академический уровень сложности
  // Академическая сложность
  return (
    // <MainLayout mobileHeader={<ChatPagesMenuMobile title={'Курс'} />} title="Курс" hiddenSidebar>
    <MainLayout
      mobileHeader={<ChatPagesMenuMobile title={''} />}
      title=''
      hiddenSidebar
      showComponentHelpFilled={false}
    >
      <MainHeader showComponentHelpFilled={false} />
      <div className={classes.headerContainer}>
        <h4 className={classes.newChatTitle}>
          Выбор задачи {props.taskName ? `для ${props.taskName}` : ''}
        </h4>
        <div className={classes.organisationBlock}>
          {props.userOrganizations
            && <h4 className={classes.newChatTitleOrg}>
              <div style={{ marginRight: 10 }}>
                Организация(и):
              </div>
              {props.userOrganizations?.map((oneOrg) => <div>{oneOrg}</div>)}
            </h4>}
        </div>
      </div>


      <NewTaskContentLayout>
        <CustomHeader1 children={'\u00A0'} />
        <ChooseTaskStepper activeStep={0} noAcademLevel={props.noAcademLevel} />
        {/* {difficultLevelsAsyncState.error ? <h1>Ошибка</h1> : null}
          {difficultLevelsAsyncState.loading ? <CircularProgress /> : null}
          {difficultLevelsAsyncState.value ? (
            <ChooserAcademicLevel difficultLevels={difficultLevelsAsyncState.value} onChoose={handleClickLevel} />
          ) : null} */}
        <ChooserAcademicLevel
          difficultLevels={difficultLevelsDemo}
          onChoose={handleClickLevel}
        />
      </NewTaskContentLayout>
    </MainLayout>
  )
}
