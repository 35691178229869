import React, { useState, useEffect, useRef } from 'react';
import MainFooter from '../../components/MainFooter/MainFooter';
import MainHeader from '../../components/MainHeader/MainHeader';
import { makeStyles } from '@material-ui/core/styles';
import ImgChamp from '../../assets/img/social_link/ImgChamp.svg';
import chatApi from '../../api/chatApi';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';


const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    minHeight: '100%'
  },
  wrapperInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    // maxHeight: '372px',
    marginTop: 48,
    marginBottom: 20,
    alignItems: 'center',
  },
  wrapperText: {
    backgroundColor: 'black',
    flexGrow: 3,
    display: 'flex',
    alignItems: 'center',
    padding: 10
  },
  wrapperImg: {
    width: '50%',
    '@media (max-width: 410px)': {
      // display: 'none'
    },
  },
  title: {
    color: 'white',
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 32,
    margin: '24px 0 87px 86px',
    whiteSpace: 'pre',
    '@media (max-width: 440px)': {
      margin: 15,
    },
  },
  text: {
    color: 'black',
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 16,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px 0 24px 0px',
    overflowY: 'auto',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  hidden: {
    display: 'none'
  },
  tetxAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    // height: 372,
    width: '100%'
  },
  arrowDownContainerActive: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#dfd8d8',
    width: 40,
    height: 40,
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    top: '90%',
    transition: 'all 450ms cubic-bezier(0.4, 0, 0.2, 1)'
  },
  arrowDownContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#dfd8d8',
    width: 40,
    height: 40,
    borderRadius: '50%',
    left: '50%',
    position: 'absolute',
    top: '72px',
    transition: 'all 450ms cubic-bezier(0.4, 0, 0.2, 1)'
  },
  customTabsClassWithBefore: {
    width: '90%',
    transition: 'all 350ms ease',
    '@media (max-width: 930px)': {
      background: 'white',
      width: '100%',
      padding: 16,
      maxHeight: 'max-content'
    },
  },
  customTabsClass: {
    margin: '0 auto',
    padding: 16,
    transition: 'all 650ms ease',
  }
});


const AboutCompany = () => {
  const classes = useStyles();
  const [headerText, setHeaderText] = useState('');
  const [descriptionText, setdescriptionText] = useState('')
  const [titleText, setTitleText] = useState('');
  const [descriptionHide, setdescriptionHide] = useState('');
  const [keywords, setKeywords] = useState('')
  const [isWrapper, setIsWrapper] = useState(false)
  const [innerWidth, setInnerWidth] = useState<number | null>(null)

  const containerRef = useRef(null)
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width
  }

  useEffect(() => {
    function handleResize() {
      setInnerWidth(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  function arrowDownHandler() {
    setIsWrapper(!isWrapper)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await chatApi.getDescription('aboutCompany');
      const headerText = response.result[0].h1;
      const descriptionText = response.result[0].descriptionText
      const titleText = response.result[0].title;
      const descriptionHide = response.result[0].descriptionHide
      const keywords = response.result[0].keywords

      setdescriptionText(descriptionText)
      setHeaderText(headerText);
      setTitleText(titleText)
      setdescriptionHide(descriptionHide)
      setKeywords(keywords)
    };
    fetchData();
  }, []);

  return (
    <>
      <MainHeader showComponentHelpFilled={false} />
      <head>
        <meta name="keywords" content={keywords} />
      </head>
      <body>
        <div className={classes.mainContainer}>
          <div className={classes.wrapperInfo}>
            <div className={classes.tetxAndImageContainer}
                 style={{ height: !isWrapper || (innerWidth && innerWidth <= 930) ? '100%' : '250px' }}>
              <div className={classes.wrapperText}>
                <h1 className={classes.title}>{headerText}</h1>
              </div>

              {!isWrapper &&
                <div className={classes.wrapperImg}>
                  <img className={classes.img} src={ImgChamp} alt="img"/>
                </div>
              }
              {innerWidth && innerWidth <= 930 &&
                <div onClick={arrowDownHandler}
                     className={isWrapper ? classes.arrowDownContainer : classes.arrowDownContainerActive}>
                  {
                    !isWrapper ?
                      <ArrowUpward/> :
                      <ArrowDownward/>
                  }
                </div>
              }
            </div>

            <div style={{position: 'relative', }}>
              <div className={isWrapper ? classes.customTabsClassWithBefore : classes.customTabsClass}>
                <span className={classes.text}>{descriptionText}</span>
              </div>
            </div>
            <div className={classes.hidden}>{descriptionHide}</div>
          </div>
          <MainFooter/>
        </div>
      </body>

    </>
  )
}

export default AboutCompany;
