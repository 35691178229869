import React, { useEffect, useRef, useState } from 'react';
import { LineChart } from "@carbon/charts-react";
import { allUsers, getModelPoint } from "../../api/chatApi/chatApi";
import {
  Button,
  DataTable,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Theme,
  ToastNotification,
  ComboBox, Loading
} from '@carbon/react';
import { makeStyles } from "@material-ui/core/styles";
import chatApi from "../../api/chatApi";
import jwt from 'jsonwebtoken'
import { TablePagination } from '@material-ui/core';
import CustomTablePagination from '../CustomPagination';
import ModelMobileElement from '../../components/ratingMobileElement/ModelMobileElement';

interface modelPointInterface {
  index: number
  start_date: string
  stop_date: string
  model_point: [{ name: string, task_point_stage_procent: string }] | null
}

const useStyles = makeStyles((theme: any) => ({
  championTablePageContainer: {
    background: '#F4F4F4',
    height: '100vh',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
  championTablePageContent: {
    display: 'flex',
    width: '98%',
    margin: '0 auto',
    height: ''
  },
  championTableFilterBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    background: '#FFFFFF',
    width: '98%',
    margin: '0 auto',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  dropDownCustomClassText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 5,
    '@media (max-width: 800px)': {
      alignSelf: 'baseline',
      marginRight: 0
    },
  },
  championSelectBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    marginRight: '15px',
    alignItems: 'flex-start',
    '& .cds--dropdown': {
      minWidth: 200
    },
    '& .cds--list-box': {
      width: 300
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15,
      marginRight: 0
    },
  },
  championSelectElement: {
    marginRight: '15px',
    display: 'flex',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginRight: 0
    },
  },
  championSelectBlockMultiSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    alignItems: 'center',
    '& .cds--dropdown': {
      minWidth: 200
    }
  },
  championTable: {
    width: '100%',
    marginRight: '1%',
    background: '#FFFFFF',
  },
  maxBallsBlock: {
    background: '#FFFFFF',
    padding: '20px 10px',
    display: 'flex',
    marginBottom: 16,
    // boxShadow: '0px -5px 5px -5px rgba(34, 60, 80, 0.48) inset'
  },
  myResultsBlock: {
    padding: '15px 10px',
    background: '#FFFFFF',
    height: 230,
  },
  maxBalls: {
    borderRight: '2px solid #DADADA',
    width: 180,
  },
  allParticipants: {
    marginLeft: 30,
  },
  titleBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '6%',
  },
  datePickerContainer: {
    display: 'flex',
    '& div.cds--date-picker': {
      display: 'flex',
      minWidth: 200
    },
    '& .cds--date-picker__input': {
      minWidth: 225
    },
    '& .cds--date-picker--range > .cds--date-picker-container': {
      width: '100%',
      marginRight: '15px',
    },
    '@media (max-width: 800px)': {
      '& div.cds--date-picker': {
        flexDirection: 'column'
      },
    },
  },
  statisticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    marginTop: '24px',
  },
  multiselectCustomClass: {

    '& div.cds--list-box__menu': {
      //width: '400px'
    }
  },
  formationButtonBlock: {
    display: 'flex',
    // justifyContent: 'center',
    '& .cds--btn--sm': {
      height: '40px',
      width: 225,
      // textAlign: 'center'
    }
  },
  customBtn: {
    borderRadius: 4,
    height: 40,
    width: 175,
    maxWidth: 225,
    background: '#0f62fe',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: 15,
    '&:hover': {
      background: '#0050e6'
    },
    '@media (max-width: 800px)': {
      width: 225,
    },
  },
  comboBoxContainer: {
    marginRight: 15,
    '@media (max-width: 800px)': {
      marginBottom: 15
    },
  },
  customComboBox: {
    // width: 325,
    // minWidth: 350,
    '@media (max-width: 800px)': {
      marginBottom: 15
    },
    '& .cds--combo-box': {
      borderRadius: 4
    }
  },
  emptyTableText: {
    whiteSpace: 'pre',
    fontSize: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    '@media (max-width: 800px)': {
      fontSize: 20,
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },
  tableContainer: {
    width: '100%',
    overflowY: 'auto',
    marginTop: 10,
    marginRight: '1%',
    padding: '0 15px',
    '& .cds--data-table-content': {
      maxHeight: '65vh',
    }
  }
  //
}))

interface ModelPointInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
  allUsersArray: unknown[]
}

export default function ModelPoint({
  countPatientTask,
  countCompleteTask,
  countFailedTask,
  allUsersArray,
}: ModelPointInterface): JSX.Element {
  const roleDecode = jwt.decode(localStorage.getItem('role'))
  const role = roleDecode.auth.split(',')
  const style = useStyles()

  const [allModelPoint, setAllModelPoint] = useState<modelPointInterface[]>([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [userInfo, setUserInfo] = useState<unknown>({})
  // const [allUsersArray, setAllUsersArray] = useState<unknown[]>([])
  const [currentUser, setCurrentUser] = useState<any>()
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [body, setBody] = useState({
    // start_date: '',
    // stop_date: '',
    login: ''
  })
  const [errorState, setErrorState] = useState(false)
  const [chartData, setChartData] = useState({
    data: [],
    options: {
      title: "",
      axes: {
        bottom: {
          title: "2019 Annual Sales Figures",
          mapsTo: "group",
          scaleType: "labels"
        },
        left: {
          mapsTo: "value",
          title: "Conversion rate",
          scaleType: "linear"
        }
      },
      height: "400px"
    }
  })

  const headerData = [
    // {
    // 	key: 'id',
    // 	header: 'Номер задачи',
    // },
    {
      key: 'name',
      header: 'Стадия',
    },
    {
      key: 'task_point_stage_procent',
      header: 'Моделируемый процент верного выполнения',
    },
    // {
    // 	key: 'model_point',
    // 	header: 'Очки',
    // },

  ]

  useEffect(() => {
    if (allUsersArray) {
      getUserInfoHandler()
    }
    // getAllUsersHandler()
  }, [])

  // async function getAllUsersHandler() {
  // 	await allUsers().then(res => {
  // 		setAllUsersArray(res.data.all_users)
  // 		console.log('getAllUsersHandler', res)
  // 	})
  // }

  async function getUserInfoHandler() {
    try {
      const response = await chatApi.getUserInfo()
      setUserInfo(response.result)
      setBody({ ...body, login: response?.result?.login })
      if (role.includes('ROLE_ADMIN') || role.includes('ROLE_ADMIN_TRAINER') || role.includes('ROLE_ADMIN_ORG')) {
        allUsersArray.filter(user => user)
      } else {
        allUsersArray.filter(user => user.login.includes(response.result.login))
      }
    } catch (err) {
      console.log('при получении данных о пользователе произошла ошибка', err)
    }
  }

  async function getModelPointHandler() {
    if (!userInfo) {
      setErrorState(true)
    }
    try {
      setIsLoading(true)
      let modelPointsArray: modelPointInterface[] = []
      const res = await getModelPoint(body)
      // res.data.model_point.model_point.length === 0 ?
      // 	setErrorState(true) :
      // 	setErrorState(false)
      //setAllModelPoint(res.data.model_point)
      if (res.data.model_point.model_point.model_point !== 0 || res.data.model_point.model_point !== 0) {
        setErrorState(false)
        res.data.model_point.model_point.map((item: unknown, id: number) => {
          return modelPointsArray.push({
            ...item,
            id,
          })
        })
        setAllModelPoint(modelPointsArray)
        calculationModelPoint(res.data.model_point)
      }
      else {
        setErrorState(true)
      }
    } catch (err) {
      setErrorState(true)
      console.log('при получении списка произошла ошибка');
    } finally {
      setTimeout(() => setErrorState(false), 5000)
      setIsLoading(false)
    }
  }

  function changeTimeHandler(value) {
    let start = ''
    let end = ''
    start = new Date(value[0]).toLocaleDateString().split('.').reverse().join('-')
    end = new Date(value[1]).toLocaleDateString().split('.').reverse().join('-')
    setStartTime(new Date(value[0]).toLocaleDateString().split('.').join('-'))
    if (end !== 'Invalid Date') {
      setEndTime(new Date(value[1]).toLocaleDateString().split('.').join('-'))
    } else {
      setEndTime('Обязательное поле')
    }
    setBody({
      ...body,
      start_date: start,
      stop_date: end
    })
  }

  function calculationModelPoint(arr: modelPointInterface[]) {
    let array: unknown = []
    let newArr = new Set()
    Array.from(arr).map((point) => {
      if (point.model_point !== null) {
        point.model_point.map((modPoint, index) => {
          array.push({
            value: point.model_point[index].task_point_stage_procent,
            key: point.start_date,
            group: point.model_point[index].name
            // group: point.model_point[index].name.split('.')[1].replace(/\s/g, '').length > 11
            //   ? `${point.model_point[index].name.split('.')[1].replace(/\s/g, '').substring(0, 11)}...`
            //   : point.model_point[index].name.split('.')[1].replace(/\s/g, ''), //если не обрезать строку до 10-11 символов, то при наведении на key с ui библиотека ложится
            // group: point.start_date,
          })
          return array
        })
      }
      return array
    })

    for (let i = 0; i < array.length; i++) {
      array.filter((a, index) => {

        if (a.group === array[i].group) {
          newArr.add(a)
        }

      })
    }
    setChartData({ data: [...newArr], options: chartData.options }) //array
  }

  function changeUserHandler(value) {
    setCurrentUser(value);
    setBody({ ...body, login: value ? value.login : null });
  }

  function itemToString(item) {
    return item && item.fio !== " " ? item.fio : item?.login ?? '';
  }

  const [isLoading, setIsLoading] = useState(false)

  function Loader() {
    if (isLoading) {
      return (
        <Loading className={'some-class'} withOverlay={false} />
      )
    } else return null
  }
  const indexOfFirstItem = page * currentPageSize;
  const indexOfLastItem = indexOfFirstItem + currentPageSize;
  const currentData = allModelPoint.slice(indexOfFirstItem, indexOfLastItem);

  const containerRef = useRef(null)
  const [innerWidth, setInnerWidth] = useState<number | null>(null)

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width
  }

  useEffect(() => {
    function handleResize() {
      setInnerWidth(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return (
    <div ref={containerRef} style={{ background: '#F4F4F4', }}>
      {
        errorState &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            iconDescription="Нет результатов"
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title="Нет результатов"
          />
        </div>
      }
      <div style={{ width: "98%", padding: "20px 0", margin: "0 auto" }}>
        <p
          style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}
        >Моделирование результатов работы
        </p>
      </div>
      <div
        style={{ background: '#FFFFFF', fontSize: '104%', width: '98%', padding: '15px 10px', margin: 'auto auto 12px auto', borderRadius: 4 }}>
        <p>Cистема осуществляет предсказательное моделирование результатов работы испытуемого без тестирования в реальной среде</p>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={style.championTableFilterBlock}>
          <div className={style.championSelectBlock}>
            <div className={style.championSelectElement}>
              <p
                className={style.dropDownCustomClassText}
              >Отображать результаты студента
              </p>
              {allUsersArray && allUsersArray.length > 0 ? (
                <ComboBox
                  id={'model-point'}
                  className={style.customComboBox}
                  placeholder={'Выберите пользователя'}
                  items={allUsersArray ? allUsersArray : []}
                  itemToString={(item) => (item && item.login !== null && item.fio !== " " ? item.fio : item?.login)}
                  onChange={({ selectedItem }) => changeUserHandler(selectedItem)}
                  selectedItem={userInfo}
                />
              ) : (
                <p style={{ whiteSpace: "pre", marginRight: "5%", marginTop: 10 }}>
                  {userInfo?.name} {userInfo?.surname}
                </p>
              )
              }
            </div>
          </div>

          <div className={style.formationButtonBlock}>
            <button
              onClick={getModelPointHandler}
              className={style.customBtn}
            >Применить фильтры
            </button>
          </div>
        </div>

        {isLoading
          ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Loader />
          </div>
          : allModelPoint && allModelPoint.length !== 0 && innerWidth >= 600 ?
            <div className={style.tableContainer}>
              <Theme theme={'g10'}>
                {
                  allModelPoint &&
                  <DataTable
                    rows={currentData}
                    headers={headerData}
                    isSortable
                  >
                    {({ rows, headers, getHeaderProps, getTableProps }) => {
                      return (
                        <TableContainer title="">
                          <Table {...getTableProps()} style={{ background: '#FFFFFF' }}>
                            <TableHead style={{
                              backgroundColor: "#FFFFFF",
                              position: "sticky",
                              top: 0,
                              zIndex: 1000,
                              borderRadius: 4,
                              boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)'
                            }}>
                              <TableRow>
                                {headers.map((header, index) => (
                                  <TableHeader key={`${header.header}${index}`} {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader >
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => {
                                return (
                                  <TableRow key={row.id}>
                                    {row.cells.map((cell, index) => {
                                      return (
                                        <TableCell key={cell.id}>{index === 1 ? `${cell.value} %` : cell.value}</TableCell>
                                      )
                                    })}
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )
                    }}
                  </DataTable>
                }
              </Theme>

            </div> :
            allModelPoint.map(model => (
              <ModelMobileElement ratingElement={model} />
            ))
        }

        {/*<div style={{width: '90%'}}>*/}
        {/*	<LineChart*/}
        {/*		data={chartData.data}*/}
        {/*		options={chartData.options}>*/}
        {/*	</LineChart>*/}
        {/*</div>*/}
      </div>
      <div style={{ margin: '25px auto 0 auto', paddingBottom: 20 }}>
        <p className={style.emptyTableText}
        >Чтобы увидеть информацию о системных ошибках заполните фильтры
        </p>
      </div>
      {<div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: 10
        }}>
        {allModelPoint.length > 0 &&
          <CustomTablePagination
            count={allModelPoint.length}
            page={page}
            rowsPerPage={currentPageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newPageSize) => {
              setCurrentPageSize(newPageSize);
              setPage(0);
            }}
          />}
      </div>
      }
    </div>
  )
}
