import { useStyles } from '../auth/Forms/_Styles';
import { createStyles, makeStyles } from '@material-ui/core';

interface ChampionMobileElementInterface {
  name: string
  subject: string
  kind: string
  points: number
}
interface ChampionMobileElement {
  ratingElement: ChampionMobileElementInterface
}

export default function ChampionMobileElement({ratingElement}: ChampionMobileElement) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      ratingMobileElementContainer: {
        border: '1px solid gray',
        borderRadius: 12,
        padding: 10,
        margin: '15px auto',
        maxWidth: 1045,
        width: '100%'
      },
      titleChapter: {
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      },
      textWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 10
      },
      descriptionChapter: {
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      }
    })
  );
  const style = useStyles()

  return (
    <div className={style.ratingMobileElementContainer}>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Раздел медицины: </p>
        <span className={style.descriptionChapter}>{ratingElement.name}</span>
      </div>
      <div style={{margin: '4px auto'}}>
        <p className={style.titleChapter}>Предмет: </p>
        <span className={style.descriptionChapter}>{ratingElement.subject}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Тема: </p>
        <span className={style.descriptionChapter}>{ratingElement.kind}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Процент освоения: </p>
        <span className={style.descriptionChapter}>{ratingElement.points}% </span>
      </div>
    </div>
  )

}