import { Avatar, Button, createStyles, Dialog, Theme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState, useLayoutEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import { useLocation } from 'react-router-dom';
import chatApi from '../../api/chatApi'
import { getMediaContent, pilotingTaskInterface, Task_ResponseData, TestingStage, getTestingstageTaskOnTask } from '../../api/chatApi/chatApi'
import { Task } from '../../api/DomainTypes'
import { AsyncState } from '../../common/Utils'
import ChatHint from '../../components/Chat/ChatHint'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import ChooseTaskStepper from '../../components/Chat/ChooseTaskStepper'
import CustomButton from '../../components/common/CustomButton'
import CustomCard from '../../components/common/CustomCard'
import CustomHeader1 from '../../components/common/CustomHeader1'
import ConfirmDialog from '../../components/ConfirmDialog'
import MainLayout from '../../components/Layout/MainLayout'
import MainHeader from '../../components/MainHeader/MainHeader'
import useFinishTaskDialogState from '../../hooks/testingTask/useFinishTaskDialogState'
import { RootState } from '../../store'
import { ChatActionType } from '../../store/chat'
import { RoutePath } from '../../types'
import NewTaskContentLayout from './NewTaskContentLayout'
import useIncreaseTaskStage from "../../hooks/testingTask/useIncreaseTaskStage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'center',
      width: '100%',
      display: 'flex',
      minHeight: '100%',
      minWidth: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    },
    cards: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      // padding: theme.spacing(5),
    },
    ava: {
      width: '160px',
      height: '160px',
      margin: `0 auto`,
      textAlign: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      // width: '100%',
      // height: '100%',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
      padding: 24
    },
    btnGroup: {
      display: 'flex'
    },
    openTaskBtn: {
      marginRight: 5
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      backgroundColor: 'red',
      fontSize: '40px'
    },
    taskName: {
      textTransform: 'none',
      // fontSize: 20,
      // fontFamily: 'Helvetica Neue',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '0.15px',
      color: '#000000',
      marginBottom: 24
    },
    patientDescription: {
      textTransform: 'none',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#212121',
      marginBottom: theme.spacing(2),
      letterSpacing: '0.02em'
    },
    patientStatus: {
      textTransform: 'none',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      color: '#212121',
      marginTop: 'auto',
      letterSpacing: '0.02em'
    },
    newChatTitle: {
      maxWidth: '1060px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px'
    },
    newChatTitleOrg: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '500px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px',
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto',

    },
    organisationBlock: {
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  })
)

type Props = {
  universityLevelId: number
  medicineId: number
  difficultId: number
  noAcademLevel?: boolean
  taskName?: string
  userOrganizations?: string[]
}

export default function ChooseTaskPage({
  universityLevelId,
  medicineId,
  difficultId,
  noAcademLevel,
  taskName,
  userOrganizations
}: Props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [
    openDialogUnfinishedTask,
    setOpenDialogUnfinishedTask
  ] = React.useState(false)

  const [currTask, setCurrTask] = React.useState<any>()
  const { close, onSubmit, open, show } = useFinishTaskDialogState()
  const [query] = useState('')
  const [items, setItems] = useState<Array<any> | null>(null)
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [body, setBody] = useState<pilotingTaskInterface>()
  const [filteredResultItemsList, setFilteredResultItemsList] = useState([])
  const [resultsAfterPiloting, setResultsAfterPiloting] = useState([])
  const [responcePiloting, setResponcePiloting] = useState({})
  const [noTask, setNotask] = useState(true)
  const [customLoading, setCustomloading] = useState(false)
  const [nextStage, setNextStage] = useState('')
  const [loading, increase, setAsyncTaskStage] = useIncreaseTaskStage(`${RoutePath.chat}`, 1)

  const taskState = useSelector<RootState, AsyncState<Task> | undefined>(
    state => {
      return state.chat.currentTaskState
    }
  )
  useQuery('currTask', async () => {
    const task: any = await chatApi.unfinishedTask()
    setCurrTask(task)
    setOpenDialogUnfinishedTask(!!task?.result)
  })

  async function getStages() {
    try {
      const stagesOnTaskResponse = await getTestingstageTaskOnTask();
      return stagesOnTaskResponse
    } catch (err) {
      console.log(err)
    }
  }

  const onClickItem = async (item: any) => {
    chatApi
      .startTask(item.id)
      .then(res => {
        dispatch({
          type: ChatActionType.SET_CHAT_HISTORY,
          data: {
            value: { messages: [], points: 0, penalty: 0 },
            loading: false,
            error: undefined
          }
        })
        dispatch({ type: 'START_LOAD_TASK' })
        // increase()
        setAsyncTaskStage()
        getStages()
          .then(res => {
            if (res?.data.testingstage_task_on_task) {
              const sortedStages = res?.data.testingstage_task_on_task.sort((a, b) => a.activeStage - b.activeStage)
              history.replace(RoutePath[sortedStages[0].routhPathCode])

            } else {
              history.replace(RoutePath.testModel)
            }
          })
        // window.location.href = '/testModel';
      })
      .catch(e => {
        if (!e?.data?.success) history.replace(RoutePath.chooseAcademLevel)
      })
  }



  const getUser = async (array?) => {
    try {
      // if(array) {
      const data = await chatApi.getUserTrainers();
      let filteredResultItems: number[] = array?.map(res => res.id)

      setBody({
        ...body,
        login: data.result.login,
        universitylevel_id: universityLevelId,
        typeofdifficultlevel_id: difficultId,
        task_id: filteredResultItems,
        medicine_id: medicineId,
      })
      setCustomloading(false)
      // }
    } catch (err) {
      console.log(err)
    }
  };


  const afterPilotingFilterArr = filteredResultItemsList.filter((item) => responcePiloting?.result?.task_id.includes(item.id))

  useEffect(() => {
    setResultsAfterPiloting(afterPilotingFilterArr)

  }, [responcePiloting])

  async function getPilotingTaskHandler(array?) {
    setCustomloading(true);
    const data = await chatApi.getUserTrainers();
    let filteredResultItems: number[] = array?.map(res => res?.id);
    setFilteredResultItemsList(array);
    try {
      if (resultItems?.length) {
        const response = await chatApi.getPilotingTask({
          login: data.result.login,
          universitylevel_id: universityLevelId,
          typeofdifficultlevel_id: difficultId,
          task_id: filteredResultItems,
          medicine_id: medicineId,
        });
        setResponcePiloting(response);
        setNotask(false)
      }
    } catch (err) {
      console.log('при уточнении произошла ошибка', err);
    }

    setCustomloading(false);
  }


  const categoriesAsyncState = useAsync(() => {
    // TODO use universityLevelId
    return chatApi
      .loadTasksBy(universityLevelId, medicineId, difficultId)
      .then(res => {
        loadMediaContents(res.result)
        getPilotingTaskHandler(res.result)
        getUser(res.result)
      })
  }, [medicineId, difficultId])


  const resultItems = useMemo(() => {
    if (query.length === 0) return items
    return items?.filter(item => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
  }, [query, items])


  const loadMediaContents = async (tasks) => {
    if (tasks) {
      const mediaLinks = await Promise.all(
        tasks.map(task =>
          getMediaContent(task.patientModel.patient.photo)
            .then(res => {
              // Если путь существует, возвращаем его, иначе возвращаем пустую строку
              const mediaLinkPath = res.data && res.data.data_base64 ? res.data.data_base64 : '';
              return { ...task, mediaLink: mediaLinkPath };
            })
            .catch(err => {
              console.error('Ошибка при получении медиаконтента для задачи:', task.id, err);
              // В случае ошибки также возвращаем задачу с пустой строкой в mediaLink
              return { ...task, mediaLink: '' };
            })
        )
      );
      setItems(mediaLinks);
    }
  };


  useEffect(() => {
    setCustomloading(true)
    getUser()
  }, [items?.length])

  useEffect(() => {
    setCustomloading(true)
    if (items && items.length) {
      getPilotingTaskHandler(items)
      // loadMediaContents(items)
      setCustomloading(false)
    }
  }, [items?.length]);

  const removeAllImagePointsView = () => {
    const keys = Object.keys(localStorage);
    const imagePointsViewKeys = keys.filter(key => key.startsWith('imagePointsView'));
    imagePointsViewKeys.forEach(key => localStorage.removeItem(key));
  };

  const openIndexedDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('ImageDotsDB', 1);
  
      request.onupgradeneeded = (event) => {
        const db = request.result;
        if (!db.objectStoreNames.contains('imageDots')) {
          db.createObjectStore('imageDots', { keyPath: 'id' });
        }
      };
  
      request.onsuccess = () => {
        resolve(request.result);
      };
  
      request.onerror = () => {
        reject(request.error);
      };
    });
  };

  const clearIndexedDB = async (): Promise<void> => {
    const db = await openIndexedDB(); 
    return new Promise((resolve, reject) => {
      const transaction = db.transaction('imageDots', 'readwrite');
      const store = transaction.objectStore('imageDots');
      
      const request = store.clear(); 
  
      request.onsuccess = () => {
        console.log('Все данные успешно удалены из хранилища IndexedDB.');
        resolve();
      };
  
      request.onerror = () => {
        console.error('Ошибка при очистке хранилища IndexedDB:', request.error);
        reject(request.error);
      };
    });
  };



  const onCloseTask = () => {
    setOpenDialogUnfinishedTask(false)
    localStorage.removeItem('newResult');
    localStorage.removeItem('numberOfCondition');
    localStorage.removeItem('surgeons');
    localStorage.removeItem('surgeonList');
    localStorage.removeItem('surveyResult');
    localStorage.removeItem('isChampCompleted');
    removeAllImagePointsView();
    clearIndexedDB();
  }
  const onSubmitTask = () => {
    onSubmit()
    onCloseTask()
  }

  const RoutePathsArray = [
    '/testModel',
    '/chat#initial',
    '/inspection',
    '/preDiagnosis',
    '/research',
    '/consultation-page',
    '/examination',
    '/diagnosis',
    '/therapy',
    '/finalTaskResult',
  ]

  const openTask = () => {
    RoutePathsArray.map((route, index) => {
      // if(currTask?.result?.testingStageId === 0) {
      //   history.replace(RoutePath.testModel)
      // }
      // if(index + 1 === currTask?.result?.testingStageId && currTask?.result?.testingStageId !== 14 && currTask?.result?.testingStageId !== 2) {
      //   history.replace(route)
      // }
      // if( currTask?.result?.testingStageId === 2) {
      //   history.replace(RoutePath.testModel)
      // }
      switch (true) {
        case currTask?.result?.testingStageId === 2: history.replace(RoutePath.chatInitial)
          break
        case currTask?.result?.testingStageId === 0: history.replace(RoutePath.testModel)
          break
        case index + 1 === currTask?.result?.testingStageId && currTask?.result?.testingStageId !== 14 && currTask?.result?.testingStageId !== 2: history.replace(route)
          break
      }
    })
    // history.replace(RoutePath.chatInitial))
    // history.replace(RoutePath.chatInitial)
  }

  useEffect(() => {
    if (!categoriesAsyncState.loading) {
      // При первом успешном получении данных (или их отсутствии)
      setInitialDataLoaded(true);
    }
  }, [categoriesAsyncState.loading]);


  // Выбор задачи
  return (
    // <MainLayout mobileHeader={<ChatPagesMenuMobile title={'Пациент'} />} title="Пациент" hiddenSidebar>
    <>
      {!customLoading &&
        <MainLayout
          mobileHeader={<ChatPagesMenuMobile title={''} />}
          title=''
          hiddenSidebar
        >
          <MainHeader showComponentHelpFilled={false} />
          <div className={classes.headerContainer}>
            <h4 className={classes.newChatTitle}>
              Выбор задачи {taskName ? `для ${taskName}` : ''}
            </h4>
            <div className={classes.organisationBlock}>
              {userOrganizations
                && <h4 className={classes.newChatTitleOrg}>
                  <div style={{ marginRight: 10 }}>
                    Организация(и):
                  </div>
                  {userOrganizations?.map((oneOrg) => <div>{oneOrg}</div>)}
                </h4>}
            </div>
          </div>

          <NewTaskContentLayout>
            <Dialog open={openDialogUnfinishedTask}>
              <div className={classes.modal}>
                <p>
                  Чтобы начать решать данную задачу, сначала закончите "
                  {currTask?.result?.task?.name}"
                </p>
                <div className={classes.btnGroup}>
                  <Button className={classes.openTaskBtn} onClick={openTask}>
                    Открыть задачу
                  </Button>
                  <CustomButton
                    variant='contained'
                    color='primary'
                    disableElevation
                    onClick={onSubmitTask}
                  >
                    Завершить
                  </CustomButton>
                </div>
              </div>
            </Dialog>

            <CustomHeader1 onClickBackBtn={history.goBack} />
            <ChooseTaskStepper
              activeStep={noAcademLevel ? 2 : 3}
              noAcademLevel={noAcademLevel}
            />

            {categoriesAsyncState.loading && customLoading ? (
              <CircularProgress style={{ alignSelf: `center` }} />
            ) : categoriesAsyncState.error ? (
              <h1 style={{ alignSelf: `center` }}>Ошибка</h1>
            ) : !customLoading && (!items || items?.length === 0) && !noTask ? (
              <h1 style={{ alignSelf: `center` }}>
                По заданным параметрам нет задач
              </h1>
            ) : (
              <>
                <div className={classes.cards}>
                  {resultsAfterPiloting?.map(task => (
                    <CustomCard key={task?.id} onClick={onClickItem} item={task}>
                      <Avatar className={classes.ava}>
                        <img
                          src={`data:image/jpeg;base64,${task?.mediaLink}`}
                          width='160'
                          alt='Patient'
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      </Avatar>
                      {task?.patientModel?.patient?.name && (
                        <div className={classes.taskName}>
                          {task?.patientModel?.patient?.name}
                        </div>
                      )}
                      <div className={classes.patientDescription}>{task?.name}</div>
                      <div className={classes.patientStatus}>{task?.description}</div>
                    </CustomCard>
                  ))}
                </div>
                {/* <ChatHint title="Выберите задачу" arrowSide="up" /> */}
              </>
            )}
          </NewTaskContentLayout>
        </MainLayout>
      }
    </>
  )
}


