import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core'
import { type } from 'os'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import chatApi from '../../api/chatApi'
import chatApiService from '../../api/chatService'
import { DifficultLevel } from '../../api/DomainTypes'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import ChooserDifficultLevel from '../../components/Chat/ChooserDifficultLevel'
import ChooseTaskStepper from '../../components/Chat/ChooseTaskStepper'
import CustomHeader1 from '../../components/common/CustomHeader1'
import MainLayout from '../../components/Layout/MainLayout'
import MainHeader from '../../components/MainHeader/MainHeader'
import { RoutePath } from '../../types'
import NewTaskContentLayout from './NewTaskContentLayout'
import { pilotingTaskInterface } from "../../api/chatApi/chatApi";

type Props = {
  universityLevelId: number
  noAcademLevel?: boolean
  taskName?: string
  taskId: any
  userOrganizations?: string[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newChatTitle: {
      maxWidth: '1060px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto'
    },
    newChatTitleOrg: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '500px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px',
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    organisationBlock: {
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  })
)

export default function ChooseDifficultPage(props: Props) {
  const classes = useStyles()
  const history = useHistory()
  const [body, setBody] = useState<pilotingTaskInterface>()
  const difficultLevelsAsyncState = useAsync(
    () => chatApiService.loadDifficultLevels(),
    []
  )
  const difficultLevels = useAsync(
    () =>
      chatApiService.loadDifficultLevelsByUniversityLevel(
        String(props.universityLevelId)
      ),
    []
  )


  const handleClickLevel = (type: DifficultLevel) => {
    setBody({ ...body, typeofdifficultlevel_id: type.id, universitylevel_id: props.universityLevelId })
    history.push(
      `${RoutePath.newChat}?universityLevelId=${props.universityLevelId}&difficultId=${type.id}`
    )
  }
  //
  // const getUser = async () => {
  //   const data = await chatApi.getUserTrainers();
  //   setBody({...body, login: data.result.login, })
  //   console.log('LOG', data, props.taskId)
  // };
  //
  // useEffect(() => {getUser()}, [])
  //
  // async function getPilotingTaskHandler() {
  //   try {
  //     const response = await chatApi.getPilotingTask(body)
  //   } catch (err) {
  //     console.log('при уточнении произошла ошибка', err)
  //   }
  // }

  // Клиническая сложность
  // Клинический уровень сложности
  return (
    // <MainLayout mobileHeader={<ChatPagesMenuMobile title={'Сложность'} />} title="Сложность" hiddenSidebar>
    <MainLayout
      mobileHeader={<ChatPagesMenuMobile title={''} />}
      title=''
      hiddenSidebar
    >
      <MainHeader showComponentHelpFilled={false} />
      {/* <h2 className={classes.newChatTitle}>&nbsp;</h2> */}
      <div className={classes.headerContainer}>
        <h4 className={classes.newChatTitle}>
          Выбор задачи {props.taskName ? `для ${props.taskName}` : ''}
        </h4>
        <div className={classes.organisationBlock}>
          {props.userOrganizations
            && <h4 className={classes.newChatTitleOrg}>
              <div style={{ marginRight: 10 }}>
                Организация(и):
              </div>
              {props.userOrganizations?.map((oneOrg) => <div>{oneOrg}</div>)}
            </h4>}
        </div>
      </div>

      <NewTaskContentLayout>
        {!props.noAcademLevel ? (
          <CustomHeader1 onClickBackBtn={history.goBack} />
        ) : null}
        <ChooseTaskStepper
          activeStep={props.noAcademLevel ? 0 : 1}
          noAcademLevel={props.noAcademLevel}
        />

        {difficultLevels.error ? <h1>Ошибка</h1> : null}
        {difficultLevels.loading ? (
          <CircularProgress style={{ alignSelf: `center` }} />
        ) : null}
        {difficultLevels.value ? (
          <>
            <ChooserDifficultLevel
              difficultLevels={difficultLevels.value}
              onChoose={handleClickLevel}
            />
          </>
        ) : null}
      </NewTaskContentLayout>
    </MainLayout>
  )
}
