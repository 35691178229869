import {
  Button,
  createStyles,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import chatApi from '../../../api/chatApi';
import SearchIcon from '@material-ui/icons/Search';
import { useQuery } from 'react-query';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    columnWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '90%',
    },
    diagnosisRow: {
      width: '100%',
      marginRight: 1,
      marginTop: 20,
      display: 'flex',
      flexDirection: 'row',
    },
    formControl: {
      width: '100%',
    },
    inputLabel: {
      top: '-13px',
      left: '13px',
    },
    textField: {
      width: '100%',
    },
    select: {
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      paddingRight: 20,
      '& .MuiSelect-root': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& .MuiInputBase-root': {
        height: '55px',
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiOutlinedInput-input': {
        padding: '8px',
        textOverflow: 'ellipsis'
      },
    },
    button: {
      height: 35,
      minWidth: 30,
      marginTop: 20,
      marginLeft: 10,
    },
  })
);


interface IClinicalDiagnosis {
  id?: number;
  clinicalDiagnosis: string;
  clinicalDiagnosisId: number;
  groupMedicineId?: number;
  groupMedicineName?: string;
  typeOfInformationLevelId?: number;
}

type Props = {
  readOnly?: boolean;
  saveAccompanyingClinicalDiagnosis: (accompanyingClinicalDiagnosis: IClinicalDiagnosis[]) => void;
  initialDiagnosis?: IClinicalDiagnosis[];
  preDiagnosisStatus?: boolean;
  taskProceedToggle: boolean;
};

const AccompanyingClinicalDiagnosisField: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const [accompanyingClinicalDiagnoses, setAccompanyingClinicalDiagnoses] = useState<IClinicalDiagnosis[]>(
    props.initialDiagnosis && props.initialDiagnosis.length > 0
      ? props.initialDiagnosis
      : [{
        clinicalDiagnosis: '',
        clinicalDiagnosisId: 0,
        groupMedicineId: 0,
        groupMedicineName: '',
        typeOfInformationLevelId: 7,
      }]
  );

  const [searchClinicalDiagnosis, setSearchClinicalDiagnosis] = useState<string[]>(['']);

  const { status, data, error, isLoading, refetch } = useQuery(
    ['ClinicalDiagnosis', {}],
    () => chatApi.getConstructorsClinicalDiagnosisAll()
  );

  const handleSearchClinicalDiagnosisChange = (index: number, event: any) => {
    const updatedSearches = [...searchClinicalDiagnosis];
    updatedSearches[index] = event.target.value;
    setSearchClinicalDiagnosis(updatedSearches);
  };

  const containsText = (text: string, searchText: string) => {
    if (!searchText || typeof searchText !== 'string') {
      return true;
    }
    return text.toLowerCase().includes(searchText.toLowerCase());
  };

  const filteredClinicalDiagnoses = (index: number) => {
    return data?.result.filter(type =>
      containsText(type.name, searchClinicalDiagnosis[index])
    );
  };

  const changeAccompanyingClinicalDiagnosis = async (
    index: number,
    clinicalDiagnosis: string,
    clinicalDiagnosisId: number,
  ) => {
    const updatedDiagnoses = [...accompanyingClinicalDiagnoses];
    updatedDiagnoses[index] = {
      ...updatedDiagnoses[index],
      clinicalDiagnosis,
      clinicalDiagnosisId,
    };
    setAccompanyingClinicalDiagnoses(updatedDiagnoses);
    await saveClinicalDiagnosis(updatedDiagnoses);
  };

  const saveClinicalDiagnosis = async (updatedDiagnoses: IClinicalDiagnosis[]) => {
    if (updatedDiagnoses) {
      props.saveAccompanyingClinicalDiagnosis(updatedDiagnoses);
    } else {
      console.log('error saveClinicalDiagnosis');
    }
  };

  const addNewDiagnosisField = () => {
    setAccompanyingClinicalDiagnoses([
      ...accompanyingClinicalDiagnoses,
      {
        clinicalDiagnosis: '',
        clinicalDiagnosisId: 0,
        groupMedicineId: 0,
        groupMedicineName: '',
        typeOfInformationLevelId: 7,
      }
    ]);
    setSearchClinicalDiagnosis([...searchClinicalDiagnosis, '']);
  };

  useEffect(() => {
    if (Array.isArray(props.initialDiagnosis) && props.initialDiagnosis.length > 0) {
      setAccompanyingClinicalDiagnoses(props.initialDiagnosis);
    }
  }, [props.initialDiagnosis]);

  return (
    <div className={classes.container}>
      <div className={classes.columnWrapper}>
        {accompanyingClinicalDiagnoses.map((diagnosis, index) => (
          <div key={index} className={classes.diagnosisRow}>
            <FormControl className={classes.formControl}>
              <InputLabel style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%'}} className={classes.inputLabel}>
                {props.preDiagnosisStatus ? 'Предварительный клинический диагноз' : 'Клинический диагноз'}
              </InputLabel>
              <Select
                className={classes.select}
                variant="outlined"
                size="medium"
                name={props.preDiagnosisStatus ? 'Предварительный клинический диагноз' : 'Клинический диагноз'}
                label={props.preDiagnosisStatus ? 'Предварительный клинический диагноз' : 'Клинический диагноз'}
                renderValue={(selected) => {
                  const diagnosisName = filteredClinicalDiagnoses(index)?.find(d => d.id === diagnosis.clinicalDiagnosisId)?.name;
                  return diagnosisName || 'Выберите диагноз';
                }}
                value={diagnosis.clinicalDiagnosisId || ''}
                fullWidth
                disableCloseOnSelect
                disabled={props.taskProceedToggle}
              >
                <ListSubheader>
                  <TextField
                    value={searchClinicalDiagnosis[index]}
                    size="medium"
                    autoFocus
                    placeholder="Введите клинический диагноз"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => handleSearchClinicalDiagnosisChange(index, event)}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </ListSubheader>
                {filteredClinicalDiagnoses(index)?.map((type) => (
                  <MenuItem
                    value={type.id}
                    key={type.id}
                    onClick={() => changeAccompanyingClinicalDiagnosis(index, type.name, type.id)}
                  >
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ))}
      </div>
      {!props.taskProceedToggle ? (
        <Button className={classes.button} variant="outlined" color="primary" onClick={addNewDiagnosisField}>
          +
        </Button>
      ) : null}
    </div>
  );
};

export default AccompanyingClinicalDiagnosisField;
