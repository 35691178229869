import React, { useState, useEffect, useRef } from 'react';
import { allMethodological, allMethodologicalTask, getMediaContent, getTaskMaterial, setLibrariesReference } from "../../api/chatApi/chatApi";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import MainFooter from "../../components/MainFooter/MainFooter";
import MainHeader from "../../components/MainHeader/MainHeader";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TreeView, SearchEditorOptions } from 'devextreme-react/tree-view';
import { Folder, Image, Document, Play, Video } from '@carbon/icons-react';
//@ts-ignore
import ImgChamp from '../../assets/img/social_link/ImgChamp.svg';
import { getTestCognitive } from "../../api/chatApi/chatApi";
import { useLocation } from "react-router-dom";
//@ts-ignore
import { ModalWrapper, Button, Loading } from '@carbon/react';
import { TrashCan, Edit, Add } from '@carbon/icons-react';
import MainModal from './Modal';
// @ts-ignore
import jwt from 'jsonwebtoken';
import ModalContent from "./ModalContent";
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles(theme => createStyles(
  {
    wrapperInfo: {
      display: 'flex',
      width: '100%',
      // flexFlow: 'row nowrap',
      // marginTop: 48,
      marginBottom: 20,
      maxHeight: 600
    },
    wrapperText: {
      backgroundColor: 'black',
      flexGrow: 5,
      marginTop: 48,
    },
    wrapperImg: {
      flexGrow: 5
    },
    btnBlock: {
      display: 'flex',
      gap: 10,
      flexDirection: 'row',
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',

      }
    },
    titel: {
      color: 'white',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      // margin: '24px 0 40px 87px',
      padding: 12
    },
    text: {
      color: 'white',
      maxWidth: 700,
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 17,
      display: 'flex',
      flexWrap: 'wrap',
      // margin: '0 0 24px 87px',
      padding: 12
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    el: {
      marginLeft: 10
    },
    arrowDownContainerActive: {
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#dfd8d8',
      width: 40,
      height: 40,
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      top: '335px',
      transition: 'all 450ms cubic-bezier(0.4, 0, 0.2, 1)'
    },
    arrowDownContainer: {
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#dfd8d8',
      width: 40,
      height: 40,
      borderRadius: '50%',
      left: '50%',
      position: 'absolute',
      top: '120px',
      transition: 'all 450ms cubic-bezier(0.4, 0, 0.2, 1)'
    },
    customTabsClassWithBefore: {
      width: '90%',
      transition: 'all 350ms ease',
      '@media (max-width: 930px)': {
        position: 'absolute',
        background: 'white',
        width: '100%',
        padding: 32,
        top: 140
      },
    },
    customTabsClass: {
      // marginTop: '50px',
      width: '90%',
      transition: 'all 650ms ease',
    }
  }
));

const MainMethodological = () => {
  const roleDecode = jwt.decode(localStorage.getItem('role'));
  const role = roleDecode.auth.split(',');
  useEffect(() => {
    loadMessages(ruMessages);
    locale(navigator.language)
  }, []);

  const classes = useStyles();
  const [data, setData] = useState([])
  const [item, setItem] = useState([])
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [switchMy, setSwitchMy] = useState<boolean>(false)


  const [openContent, setOpenContent] = useState(false)
  const [elem, setElem] = useState<any>([])

  const clickContent = (item) => {
    setElem(item)
    setOpenContent(true)
  }

  const [reRequest, setReRequest] = useState(false)

  const location = useLocation();
  const [toggle, setToggle] = useState(false)


  const getAllMethodological = async () => {
    setSwitchMy(true)
    setReRequest(false)
    setToggle(false)
    await allMethodological()
      .then(res => setData(res.data.libraries_reference))
      .catch(err => console.log(err))
      .finally(() => setSwitchMy(false))
  }



  const getGroup = async () => {
    setSwitchMy(true)
    setToggle(true)
    await getTaskMaterial(false)
      .then(res => {
        const group = res?.data.ducational_trajectory
        if (group.length > 0) {
          allMethodologicalTask(group)
            .then(res => setData(res.data.libraries_reference_group_theme))
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
      .finally(() => setSwitchMy(false))
  }

  const getGroup2 = async (loc) => {
    setSwitchMy(true)
    setReRequest(false)
    setToggle(false)
    await getTaskMaterial(true, Number(loc))
      .then(res => {
        const group = res?.data.ducational_trajectory
        allMethodologicalTask(group)
          .then(res => setData(res.data.libraries_reference_group_theme))
      })
      .catch(err => console.log(err))
      .finally(() => setSwitchMy(false))
  }

  useEffect(() => {
    if (location.state) {
      getGroup2(location.state)
    }
    else {
      getAllMethodological()
    }
  }, [reRequest]);

  const changeButton = (item) => {
    setItem(item)
    setTitle('Редактирование')
    setOpen(true)
  }

  const createButton = (item) => {
    setItem(item)
    setTitle('Создать')
    setOpen(true)
  }

  const deleteButton = async (item) => {
    await setLibrariesReference(false, 0, item.name, item.p_id, item.theme, item.type, item.id)
      .then(res => setReRequest(true))
      .catch(err => console.log(err))
  }

  const clickNew = () => {
    const itemMy = {
      p_id: -1,
      type: 1
    }
    setItem(itemMy)
    setTitle('Создать раздел верхнего уровня')
    setOpen(true)
  }



  const Item = (item) => {

    return (
      <div style={{ display: 'flex', fontFamily: 'Helvetica Neue', flexDirection: 'column' }}>
        {item.type === 1
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Folder style={{ marginRight: 5 }} />
              <div>{item.name}</div>
            </div>
            {role.includes('ROLE_ADMIN') &&
              <div>
                <Add className={classes.el} onClick={() => createButton(item)} />
                <Edit className={classes.el} onClick={() => changeButton(item)} />
                <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
              </div>
            }

          </div>

          :
          null
        }

        {item.type === 2
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Document style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>
            {role.includes('ROLE_ADMIN') &&
              <div>
                <Edit className={classes.el} onClick={() => changeButton(item)} />
                <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
              </div>
            }
          </div>

          :
          null
        }

        {item.type === 3
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', width: '80%' }} onClick={() => clickContent(item)}>
              <Document style={{ marginRight: 5 }} />
              <div>{item.name}</div>
            </div>
            {role.includes('ROLE_ADMIN') &&
              <div>
                <Edit className={classes.el} onClick={() => changeButton(item)} />
                <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
              </div>
            }
          </div>

          :
          null
        }

        {item.type === 4
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Image style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {role.includes('ROLE_ADMIN') &&
                <div>
                  <Edit className={classes.el} onClick={() => changeButton(item)} />
                  <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
                </div>
              }
            </div>

          </div>

          :
          null
        }

        {item.type === 5
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Play style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {role.includes('ROLE_ADMIN') &&
                <div>
                  <Edit className={classes.el} onClick={() => changeButton(item)} />
                  <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
                </div>
              }
            </div>
          </div>

          :
          null
        }

        {item.type === 6
          ?
          <div style={{ display: 'flex', marginTop: 4, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Video style={{ marginRight: 5 }} />
              <div onClick={() => clickContent(item)}>{item.name}</div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {role.includes('ROLE_ADMIN') &&
                <div>
                  <Edit className={classes.el} onClick={() => changeButton(item)} />
                  <TrashCan className={classes.el} onClick={() => deleteButton(item)} />
                </div>
              }
            </div>
          </div>

          :
          null
        }

      </div>

    )
  }

  const searchExpr = ["medicine_name", "name", "text"];
  const [innerWidth, setInnerWidth] = useState<number | null>(null)
  const [isWrapper, setIsWrapper] = useState(false)
  const containerRef = useRef(null)

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width
  }

  useEffect(() => {
    function handleResize() {
      setInnerWidth(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  function arrowDownHandler() {
    setIsWrapper(!isWrapper)
  }
  return (
    <>
      <MainHeader />
      <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ModalContent open={openContent} setOpen={setOpenContent} item={elem} />
        <Loading active={switchMy} small={false} withOverlay={true} description="Loading..." />
        <MainModal open={open} title={title} setOpen={setOpen} item={item} setReRequest={setReRequest} />
        <div className={classes.wrapperInfo}>
          <div className={classes.wrapperText}>
            <h2 className={classes.titel}>Методические материалы</h2>
            {
              !isWrapper &&
              <div className={classes.text}>
                Информационно-методические материалы - это банк документов, в котором собраны все учебные пособия,
                клинические и методические рекомендации, сведения, информационные материалы, перечни и памятки,
                благодаря которым Вы сможете эффективно решать ситуационные задачи в тренажёре NeuroDoc
              </div>
            }

          </div>
          { (innerWidth && innerWidth >= 930) || isWrapper &&
            <div className={classes.wrapperImg}>
              <img className={classes.img} src={ImgChamp} alt="img"/>
            </div>
          }
        </div>
        {innerWidth && innerWidth <= 930 &&
          <div onClick={arrowDownHandler} className={isWrapper ? classes.arrowDownContainer : classes.arrowDownContainerActive}>
            {
              isWrapper ?
                <ArrowDownward /> :
                <ArrowUpward />
            }
          </div>
        }
        <div className={isWrapper ? classes.customTabsClassWithBefore : classes.customTabsClass}>
          <div style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div className={classes.btnBlock}>
              <Button
                onClick={() => getGroup()}
                style={{ whiteSpace: 'nowrap', marginBottom: 10, borderRadius: 4, minWidth: 340 }}>
                Индивидуальный профессиональный маршрут
              </Button>
              <Button style={{ marginBottom: 10, borderRadius: 4 }}
                disabled={toggle ? false : true}
                onClick={() => location.state ? getGroup2(location.state) : getAllMethodological()}
              >
                Отмена
              </Button>
            </div>

            {role.includes('ROLE_ADMIN') &&
              <Button style={{ marginBottom: 10, borderRadius: 4 }}
                // disabled={toggle ? false : true}
                onClick={() => clickNew()}
              >
                Добавить раздел верхнего уровня
              </Button>
            }
          </div>
          {data.length > 0
            ?
            <div style={{ margin: '0px 0px 160px 0px' }}>
              <TreeView
                keyExpr="key"
                displayExpr="name"
                itemsExpr="child"
                dataSource={data}
                searchEnabled={true}
                searchExpr={searchExpr}
                searchMode="startswith"
                itemRender={Item}
              >

                <SearchEditorOptions
                  placeholder="Поиск"
                />
              </TreeView>
              {Item(data)}
            </div>
            :
            <div style={{ marginLeft: 50, fontSize: 20, marginBottom: 40 }}>Не найдены данные</div>
          }
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default MainMethodological;
