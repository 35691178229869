import { Avatar, Button, createStyles, Dialog, Theme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import chatApi from '../../api/chatApi'
import { Task_ResponseData } from '../../api/chatApi/chatApi'
import { Task } from '../../api/DomainTypes'
import { AsyncState } from '../../common/Utils'
import ChatHint from '../../components/Chat/ChatHint'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import ChooseTaskStepper from '../../components/Chat/ChooseTaskStepper'
import CustomButton from '../../components/common/CustomButton'
import CustomCard from '../../components/common/CustomCard'
import CustomHeader1 from '../../components/common/CustomHeader1'
import ConfirmDialog from '../../components/ConfirmDialog'
import MainLayout from '../../components/Layout/MainLayout'
import useFinishTaskDialogState from '../../hooks/testingTask/useFinishTaskDialogState'
import { RootState } from '../../store'
import { ChatActionType } from '../../store/chat'
import { RoutePath } from '../../types'
import NewTaskContentLayout from './NewTaskContentLayout'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'center',
      width: '100%',
      display: 'flex',
      minHeight: '100%',
      minWidth: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    },
    cards: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      backgroundColor: 'siver'
      // padding: theme.spacing(5),
    },
    ava: {
      width: '160px',
      height: '160px',
      margin: `0 auto`,
      textAlign: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      // width: '100%',
      // height: '100%',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
      padding: 24
    },
    btnGroup: {
      display: 'flex'
    },
    openTaskBtn: {
      marginRight: 5
    },
    taskName: {
      textTransform: 'none',
      // fontSize: 20,
      // fontFamily: 'Helvetica Neue',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '0.15px',
      color: '#000000',
      marginBottom: 24
    },
    patientDescription: {
      textTransform: 'none',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      color: '#212121',
    },
    newChatTitle: {
      maxWidth: '1060px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto'

    },
    newChatTitleOrg: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '500px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px',
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    organisationBlock: {
      '@media (max-width: 950px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
    }
  })
)

type Props = {
  universityLevelId: number
  medicineId: number
  difficultId: number
  noAcademLevel?: boolean
  userOrganizations?: string[]
  taskName?: string
}

export default function ChooseHardTaskPage({
  universityLevelId,
  medicineId,
  difficultId,
  noAcademLevel,
  taskName,
  userOrganizations
}: Props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [
    openDialogUnfinishedTask,
    setOpenDialogUnfinishedTask
  ] = React.useState(false)
  const [currTask, setCurrTask] = React.useState<any>()
  const { close, onSubmit, open, show } = useFinishTaskDialogState()

  const taskState = useSelector<RootState, AsyncState<Task> | undefined>(
    state => {
      return state.chat.currentTaskState
    }
  )

  // useQuery('currTask', async () => {
  //   const task: any = await chatApi.unfinishedTask();
  //   setCurrTask(task);
  //   setOpenDialogUnfinishedTask(!!task?.result);
  // });
  // console.log(currTask)
  useEffect(() => {
    unfinishedTask()
    // if(currTask == undefined) {
    //   getRandom()
    // }
  }, [])

  async function unfinishedTask() {
    try {
      const task: any = await chatApi.unfinishedTask()
      setCurrTask(task)
      setOpenDialogUnfinishedTask(!!task?.result)
      if (!!task.result === false) {
        await getRandom()
      }
    } catch (err) {
      console.log(`при получении статуса задачи произошла ошибка: ${err}`)
    }
  }

  const onClickItem = (item: any) => {
    chatApi
      .startTask(item.id)
      .then(res => {
        dispatch({
          type: ChatActionType.SET_CHAT_HISTORY,
          data: {
            value: { messages: [], points: 0, penalty: 0 },
            loading: false,
            error: undefined
          }
        })
        dispatch({ type: 'START_LOAD_TASK' })
        // window.location.href = '/testModel';
      })
      .catch(e => {
        if (!e?.data?.success) history.replace(RoutePath.testModel)
      })
  }

  const [randomId, setRandomId] = useState<number>()

  function getRandomPatient(id: number) {
    chatApi
      .startTask(id)
      .then(res => {
        dispatch({
          type: ChatActionType.SET_CHAT_HISTORY,
          data: {
            value: { messages: [], points: 0, penalty: 0 },
            loading: false,
            error: undefined
          }
        })
        dispatch({ type: 'START_LOAD_TASK' })
        history.replace(RoutePath.testModel)
        // window.location.href = '/testModel';
      })
      .catch(e => {
        if (!e?.data?.success) history.replace(RoutePath.testModel)
      })
  }

  async function getRandom() {
    try {
      const response = await chatApi.getRandomTask()
      getRandomPatient(response.result.id)
      setRandomId(response.result.id)
    } catch (err) {
      console.log(err)
    }
  }

  const [query] = useState('')
  const [items, setItems] = useState<Array<any> | null>(null)

  const categoriesAsyncState = useAsync(() => {
    // TODO use universityLevelId
    return chatApi
      .loadTasksBy(universityLevelId, medicineId, difficultId)
      .then(res => {
        setItems(res.result)
      })
    // return chatApi.getRandomTask().then(res => {
    //   setItems([res.result]);
    //   setRandomId(res.result.id)
    //   console.log(res.result)
    // });
  }, [medicineId, difficultId])

  const resultItems = useMemo(() => {
    if (query.length === 0) return items
    return items?.filter(item => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
  }, [query, items])

  const onCloseTask = () => {
    setOpenDialogUnfinishedTask(false)
    history.replace(RoutePath.chooseAcademLevel)
  }
  const onSubmitTask = () => {
    onSubmit()
    onCloseTask()
    // return history.goBack
  }

  const RoutePathsArray = [
    '/testModel',
    '/chat#initial',
    '/inspection',
    '/preDiagnosis',
    '/research',
    '/consultation-page',
    '/examination',
    '/diagnosis',
    '/therapy',
    '/finalTaskResult',
  ]
  const openTask = () => {
    RoutePathsArray.map((route, index) => {
      switch (true) {
        case currTask?.result?.testingStageId === 2: history.replace(RoutePath.chatInitial)
          break
        case currTask?.result?.testingStageId === 0: history.replace(RoutePath.testModel)
          break
        case currTask?.result?.testingStageId === 7: history.replace(RoutePath.examination)
          break
        case index + 1 === currTask?.result?.testingStageId && currTask?.result?.testingStageId !== 14 && currTask?.result?.testingStageId !== 2: history.replace(route)
          break
      }
    })
    // history.replace(RoutePath.chatInitial))
    // history.replace(RoutePath.chatInitial)
    getRandom()
  }


  const openIndexedDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('ImageDotsDB', 1);
  
      request.onupgradeneeded = (event) => {
        const db = request.result;
        if (!db.objectStoreNames.contains('imageDots')) {
          db.createObjectStore('imageDots', { keyPath: 'id' });
        }
      };
  
      request.onsuccess = () => {
        resolve(request.result);
      };
  
      request.onerror = () => {
        reject(request.error);
      };
    });
  };

  const clearIndexedDB = async (): Promise<void> => {
    const db = await openIndexedDB(); 
    return new Promise((resolve, reject) => {
      const transaction = db.transaction('imageDots', 'readwrite');
      const store = transaction.objectStore('imageDots');
      
      const request = store.clear(); 
  
      request.onsuccess = () => {
        console.log('Все данные успешно удалены из хранилища IndexedDB.');
        resolve();
      };
  
      request.onerror = () => {
        console.error('Ошибка при очистке хранилища IndexedDB:', request.error);
        reject(request.error);
      };
    });
  };

  //удаление данных из localStorage
  const removeAllImagePointsView = () => {
    const keys = Object.keys(localStorage);
    const imagePointsViewKeys = keys.filter(key => key.startsWith('imagePointsView'));
    imagePointsViewKeys.forEach(key => localStorage.removeItem(key));
  };
  const cleaningLocalStorage = () => {
    localStorage.removeItem('newResult');
    localStorage.removeItem('conditionType');
    localStorage.removeItem('numberOfCondition');
    localStorage.removeItem('surgeons');
    localStorage.removeItem('surgeonList');
    localStorage.removeItem('surveyResult');
    localStorage.removeItem('isChampCompleted');
    removeAllImagePointsView();
    clearIndexedDB();
  }
  // Выбор задачи
  return (
    // <MainLayout mobileHeader={<ChatPagesMenuMobile title={'Пациент'} />} title="Пациент" hiddenSidebar>
    <MainLayout
      mobileHeader={<ChatPagesMenuMobile title={''} />}
      title=''
      hiddenSidebar
    >
      <div className={classes.headerContainer}>
        <h4 className={classes.newChatTitle}>
          Выбор задачи {taskName ? `для ${taskName}` : ''}
        </h4>
        <div className={classes.organisationBlock}>
          {userOrganizations
            && <h4 className={classes.newChatTitleOrg}>
              <div style={{ marginRight: 10 }}>
                Организация(и):
              </div>
              {userOrganizations?.map((oneOrg) => <div>{oneOrg}</div>)}
            </h4>}
        </div>

      </div>
      <NewTaskContentLayout>
        <Dialog open={openDialogUnfinishedTask}>
          <div className={classes.modal}>
            <p>
              Чтобы начать решать данную задачу, сначала закончите "
              {currTask?.result?.task?.name}"
            </p>
            <div className={classes.btnGroup}>
              <Button className={classes.openTaskBtn} onClick={openTask}>
                Открыть задачу
              </Button>
              <CustomButton
                variant='contained'
                color='primary'
                disableElevation
                onClick={() => {
                  show()
                  cleaningLocalStorage()
                }}
              >
                Завершить
              </CustomButton>
            </div>
          </div>
        </Dialog>

        <ConfirmDialog
          open={open}
          title='Завершить коммуникацию с пациентом?'
          content='Вы уверены, что хотите завершить коммуникацию? После этого вы не сможете задавать вопросы...'
          onClose={close}
          onSubmit={onSubmitTask}
        />
        <CustomHeader1 onClickBackBtn={history.goBack} />
        <ChooseTaskStepper
          activeStep={noAcademLevel ? 2 : 3}
          noAcademLevel={noAcademLevel}
        />

        {categoriesAsyncState.loading ? (
          <CircularProgress style={{ alignSelf: `center` }} />
        ) : categoriesAsyncState.error ? (
          <h1 style={{ alignSelf: `center` }}>Загрузка...</h1>
        ) : !items || items?.length === 0 ? (
          <h1 style={{ alignSelf: `center` }}>
            По заданным параметрам нет задач
          </h1>
        ) : (
          <>
            <div className={classes.cards}>
              {resultItems &&
                resultItems?.map(task => (
                  <CustomCard key={task.id} onClick={onClickItem} item={task}>
                    <Avatar className={classes.ava}>
                      <img
                        src={task.patientModel.patient.photo}
                        width='160'
                        alt='Patient'
                      />
                    </Avatar>
                    {task?.patientModel?.patient?.name && (
                      <div className={classes.taskName}>
                        {task?.patientModel?.patient?.name}
                      </div>
                    )}
                    <div className={classes.patientDescription}>
                      {task.name}
                    </div>
                  </CustomCard>
                ))}
            </div>
            {/* <ChatHint title="Выберите задачу" arrowSide="up" /> */}
          </>
        )}
      </NewTaskContentLayout>
    </MainLayout>
  )
}
