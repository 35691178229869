import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import MainLayout from '../../Layout/MainLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import chatApi from '../../../api/chatApi';
import { RoutePath } from '../../../types';
import CustomButton from '../../common/CustomButton';

interface Stage {
  id: number | string;
  activeStage: number;
  routePath: string;
  routhPathCode: string;
  code?: string;
  legend_id?: boolean;
}

interface DiagnosticCriterion {
  id: number;
  name: string;
  descriptions?: DiagnosticCriteriaDescription[];
}

interface DiagnosticCriteriaDescription {
  id: number | string;
  diagnosticCriteriaId: number | string;
  type: string | null;
  demonstration: string;
  description: string;
  points: number;
}

interface SavedAnswer {
  description: {
    id: number | string;
    diagnosticCriteriaId: number | string;
    type: string | null;
    demonstration: string;
    description: string;
    points: number;
  };
  id: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 16px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(800)]: {
      padding: '10px 8px',
    },
  },
  categoryBox: {
    padding: '16px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    marginBottom: '10px',
    [theme.breakpoints.down(800)]: {
      padding: '8px',
      marginBottom: '8px',
    },
  },
  categoryTitle: {
    marginBottom: '16px',
    fontWeight: 800,
    fontSize: '1.8rem !important',
    textAlign: 'center',
    [theme.breakpoints.down(800)]: {
      fontSize: '1.5rem !important',
      marginBottom: '12px',
    },
  },
  sectionTitle: {
    fontWeight: 'bold',
    margin: '14px 8px !important',
    padding: '5px 10px',
    textAlign: 'center',
    fontSize: '1.3rem !important',
    backgroundColor: 'rgb(195, 223, 255)',
    borderRadius: '8px',
    [theme.breakpoints.down(800)]: {
      fontSize: '1.1rem !important',
      padding: '4px 8px',
    },
  },
  sectionTitleDemonstration: {
    fontWeight: 'bold',
    marginBottom: '8px',
    padding: '5px 10px',
    textAlign: 'left',
    fontSize: '1.25rem !important',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem !important',
      padding: '3px 6px',
    },
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    marginTop: '8px',
    alignItems: 'center',
    [theme.breakpoints.down(800)]: {
      display: 'flex',
      justifyContent: 'center',
      gap: '4px',
      marginTop: '4px'
    },
  },
  questionBox: {
    marginBottom: '16px',
    border: '1px solid gray',
    padding: '10px',
    borderRadius: '8px',
    [theme.breakpoints.down(800)]: {
      marginBottom: '8px',
      padding: '6px'
    },
  },
  tableContainer: {
    marginTop: '16px',
    overflowX: 'auto',
    [theme.breakpoints.down(800)]: {
      marginTop: '8px',
    },
  },
  table: {
    width: '100%',
    minWidth: 600,
    [theme.breakpoints.down(800)]: {
      minWidth: 300,
    },
  },
  tableCell: {
    fontSize: '16px',
    padding: '8px',
    [theme.breakpoints.down(800)]: {
      fontSize: '12px',
      padding: '0px',
    },
  },
  verticalCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '12px',
  },
  labelRow: {
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  resultBox: {
    border: '4px solid #4F90DB',
    borderRadius: '8px',
    padding: '40px',
    fontSize: '38px',
    fontWeight: 600,
    marginTop: '10px',
    [theme.breakpoints.down(800)]: {
      padding: '20px',
      fontSize: '24px',
      marginTop: '8px',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    marginTop: '8px',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      gap: '4px',
      marginTop: '4px',
    },
  },
}));

const QuestionnairePage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:800px)');
  const [stages, setStages] = useState<Stage[]>([]);
  const [manualActiveStage, setManualActiveStage] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<DiagnosticCriterion[]>([]);
  const [title, setTitle] = useState('');
  const [isOpenScale, setIsOpenScale] = useState<boolean>(false);
  const [diagnosticQuestions, setDiagnosticQuestions] = useState<DiagnosticCriteriaDescription[]>();
  const [scaleQuestions, setScaleQuestions] = useState<DiagnosticCriteriaDescription[]>();
  const [savedAnswers, setSavedAnswers] = useState<SavedAnswer[]>([]);
  const [userTaskProceedId, setUserTaskProceedId] = useState<number>();
  const [currentTaskId, setCurrentTaskId] = useState<number>();
  const [taskCriteria, setTaskCriteria] = useState<any>();
  const [totalClinicAnswers, setTotalClinicAnswers] = useState<number>(0);
  const [totalImmonologyAnswers, setTotalImmonologyAnswers] = useState<number>(0);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [isScaleResult, setIsScaleResult] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmptyQuestionsList, setIsEmptyQuestionsList] = useState<boolean>(false);
  const [isShowNextStageBtn, setIsShowNextStageBtn] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const scaleTitleStr = "Отметьте пункт, соответствующий проявлению, имевшему место на момент осмотра или в течении 10 предшествовавших осмотру дней";

  useEffect(() => {
    getAllCriteria();
    getAllCriteriaDescriptions();
    fetchAllStages();
    fetchSavedAnswers();
    unfinishedTask();
  }, []);

  useEffect(() => {
    getAllCriteriaDescriptions();
  }, [taskCriteria]);

  async function unfinishedTask() {
    setIsLoading(true);
    try {
      const task: any = await chatApi.unfinishedTask();
      setCurrentTaskId(task.result.taskId);
    } catch (err) {
      console.log(`Ошибка получения статуса задачи: ${err}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function getHistoryStage() {
    try {
      const res = await chatApi.getTestingStageHistory();
      const result = res.result.find((el: any) => el.activeStageId === 10);
      const isStageCompleted = res.result.find((el: any) => el.activeStageId > 10);
      setUserTaskProceedId(res.result[0].userTaskProceedId);
      if (result && isStageCompleted) {
        setManualActiveStage(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
      const titleQuestionnaire = result.data.testingstage_task_on_task.find(
        (oneStage: { code: string }) => oneStage.code === 'questionnaire'
      );
      if (titleQuestionnaire) setTitle(titleQuestionnaire.testingstage_descr);
      await getHistoryStage();
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  async function setHistoryStage(testingStage: number) {
    try {
      await chatApi.setTestingStageHistory(testingStage);
    } catch (error) {
      console.log(error);
    }
  }

  async function goToNextStage() {
    try {
      const currentPath = location.pathname;
      const currentStageObj = stages.find(stage => stage.routePath === currentPath);
      if (!currentStageObj) return;

      await setHistoryStage(currentStageObj.activeStage);
      const currentActiveStage = currentStageObj.activeStage;
      const sortedNextStages = stages
        .filter(stage => stage.activeStage > currentActiveStage)
        .sort((a, b) => a.activeStage - b.activeStage);

      if (sortedNextStages.length === 0) {
        console.warn('Нет доступных следующих стадий');
        return;
      }
      const finalNextStage = sortedNextStages[0];
      if (finalNextStage.legend_id) {
        history.push(RoutePath.legend);
      } else {
        history.push(finalNextStage.routePath);
      }
    } catch (error) {
      console.log('Ошибка функции перехода на следующий этап (goToNextStage): ', error)
    }
  }

  async function getAllCriteria() {
    setIsLoading(true)
    try {
      const result = await chatApi.getAllDiagnosticCriteria();
      setCriteria(result.result || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchCriteria = async () => {
      if (currentTaskId) {
        setIsLoading(true)
        try {
          const result = await chatApi.getDiagnosticCriteriaByTask(currentTaskId);
          if (result.result.length === 0) {
            setIsEmptyQuestionsList(true)
            setIsShowNextStageBtn(true)
          }
          setTaskCriteria(result);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(true)
        }
      }
    };
    fetchCriteria();
  }, [currentTaskId]);

  async function getAllCriteriaDescriptions() {
    setIsLoading(true)
    try {
      const result = await chatApi.getAllDiagnosticCriteriaDescriptions();
      const finalResult = taskCriteria?.result
        .map((criterion: any) => {
          return result.result.filter(
            (el: DiagnosticCriteriaDescription) => el.diagnosticCriteriaId === criterion.diagnosticCriteriaId
          );
        })
        .flat();
      if (finalResult) {
        setDiagnosticQuestions(finalResult.filter((el: DiagnosticCriteriaDescription) => el.diagnosticCriteriaId === 1));
        setScaleQuestions(finalResult.filter((el: DiagnosticCriteriaDescription) => el.diagnosticCriteriaId !== 1));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  }

  const getResult = async () => {
    try {
      const result = await chatApi.getDiagnosticCriteriaUserTaskResult();
      return result;
    } catch (error) {
      console.log('Ошибка функции getResult: ', error)
    }
  };

  async function fetchSavedAnswers() {
    try {
      const response = await getResult();
      const answerForSave: SavedAnswer[] =
        typeof response?.result === 'object'
          ? Object.values(response.result).flat() as SavedAnswer[]
          : [];
      const clinicalAnswers = answerForSave.filter((answer: SavedAnswer) => answer.description.type === "Клинические критерии");
      setTotalClinicAnswers(clinicalAnswers.length);

      const immonologyAnswers = answerForSave.filter((answer: SavedAnswer) => answer.description.type === "Иммунологические критерии");
      setTotalImmonologyAnswers(immonologyAnswers.length);

      const totaScalePoints = answerForSave
        .filter((answer: SavedAnswer) => answer.description.type === null)
        .reduce((acc, currentValue) => acc + currentValue.description.points, 0);
      setTotalPoints(totaScalePoints);

      setSavedAnswers(answerForSave);
    } catch (error) {
      console.error('Ошибка при получении сохранённых ответов:', error);
    }
  }

  async function submitAnswers() {
    try {
      setIsOpenScale(true);
      await getResult();
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * toggleAnswer — функция-тогглер для чекбокса.
   */
  async function toggleAnswer(diagnosticCriteriaDescriptionId: number) {
    const existingAnswer = savedAnswers.find(
      (ans) => ans?.description?.id === diagnosticCriteriaDescriptionId
    );

    if (existingAnswer) {
      try {
        await chatApi.deleteDiagnosticCriteriaUserTask(existingAnswer.id);
        setSavedAnswers((prev) =>
          prev.filter((ans) => ans?.description?.id !== diagnosticCriteriaDescriptionId)
        );
      } catch (error) {
        console.error('Ошибка при удалении ответа:', error);
      } finally {
        await fetchSavedAnswers();
      }
    } else {
      const body = {
        diagnosticCriteriaDescriptionId,
        usertaskproceedId: userTaskProceedId
      };
      try {
        const response = await chatApi.createDiagnosticCriteriaUserTask(body);
        const fullQuestion = diagnosticQuestions?.find(
          (q) => q.id === diagnosticCriteriaDescriptionId
        );
        const saved = {
          ...response.result,
          description: fullQuestion || {}
        };
        setSavedAnswers((prev) => [...prev, saved]);
      } catch (error) {
        console.error('Ошибка при сохранении ответа:', error);
      } finally {
        await fetchSavedAnswers();
      }
    }
  }

  const immoType = diagnosticQuestions?.find(el => el.type !== 'Клинические критерии')?.type;

  return (
    <MainLayout title={title}>
      <Box className={classes.root}>
        {isLoading ?
          <Box sx={{
            background: 'rgba(255, 255, 255, 0.6)',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 999,
            display: 'flex',
            alignSelf: 'center',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CircularProgress />
          </Box> :
          <Box>
            {
              manualActiveStage ?
                <Box sx={{
                  marginTop: '10px',
                  backgroundColor: '#dadada',
                  borderRadius: '8px'
                }}>
                  <Typography className={classes.categoryTitle}>
                    Этап уже пройден
                  </Typography>
                </Box>
                :
                null}
            {/* Блок клинических критериев */}
            {!isEmptyQuestionsList ?
              <>
                <Box>
                  <Typography className={classes.categoryTitle}>
                    {criteria && criteria[0]?.name}
                  </Typography>
                  <Typography variant="h5" className={classes.sectionTitle}>
                    {diagnosticQuestions && diagnosticQuestions[0]?.type}
                  </Typography>
                  {diagnosticQuestions?.filter(el => el.type === 'Клинические критерии').map((criterion) => {
                    const lines = criterion.description
                      .split('\n')
                      .map(line => line.trim())
                      .filter(line => line.length > 0);

                    return (
                      <Box key={criterion.id} className={classes.questionBox}>
                        <Typography className={classes.sectionTitleDemonstration}>
                          {criterion.demonstration}
                        </Typography>
                        <List>
                          {lines.map((line, idx) => (
                            <ListItem key={idx} sx={{ fontSize: '16px' }}>
                              {line}
                            </ListItem>
                          ))}
                        </List>
                        <Box className={classes.checkboxRow}>
                          <FormControlLabel
                            control={<Checkbox
                              disabled={isOpenScale || manualActiveStage}
                              checked={!!savedAnswers.find((ans) => ans.description?.id === criterion.id)}
                              onChange={() => toggleAnswer(Number(criterion.id))} />}
                            label={<Typography
                              variant='h6'
                              sx={{
                                fontFamily: 'Helvetica',
                                fontWeight: 400,
                                fontSize: 16,
                                color: isOpenScale || manualActiveStage ? 'grey' : 'black'
                              }}
                            >
                              Да
                            </Typography>} />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                {/* Блок иммунологических критериев */}
                <Box>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    {immoType}
                  </Typography>
                  {diagnosticQuestions?.filter(el => el.type === 'Иммунологические критерии').map((criterion) => {
                    const lines = criterion.description
                      .split('\n')
                      .map(line => line.trim())
                      .filter(line => line.length > 0);

                    return (
                      <Box key={criterion.id} className={classes.questionBox}>
                        <Typography className={classes.sectionTitleDemonstration}>
                          {criterion.demonstration}
                        </Typography>
                        <List>
                          {lines.map((line, idx) => (
                            <ListItem key={idx} sx={{ fontSize: '16px' }}>
                              {line}
                            </ListItem>
                          ))}
                        </List>
                        <Box className={classes.checkboxRow}>
                          <FormControlLabel
                            control={<Checkbox
                              disabled={isOpenScale || manualActiveStage}
                              checked={!!savedAnswers.find((ans) => ans.description?.id === criterion.id)}
                              onChange={() => toggleAnswer(Number(criterion.id))} />}
                            label={<Typography variant="h6"
                              sx={{
                                fontFamily: 'Helvetica',
                                fontWeight: 400,
                                fontSize: 16,
                                color: isOpenScale || manualActiveStage ? 'grey' : 'black',
                              }}
                            >
                              Да
                            </Typography>} />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </> :
              <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '85vh' }}>
                <Typography variant='h6'>
                  Для данной задачи опросов не найдено
                </Typography>
              </Box>}

            {/* Блок итогов */}
            {!isShowNextStageBtn ?
              <Box className={classes.buttonGroup}>
                {!isOpenScale && !manualActiveStage ? (
                  <CustomButton onClick={submitAnswers}>
                    Отправить ответы
                  </CustomButton>
                ) : (
                  <Box>
                    <Typography className={classes.sectionTitle}>
                      Итого: {totalClinicAnswers + totalImmonologyAnswers} из {diagnosticQuestions?.length} критериев,
                      из них {totalClinicAnswers} клинических критериев и {totalImmonologyAnswers} иммунологических критериев.
                    </Typography>
                  </Box>
                )}
              </Box> :
              null
            }

            {/* Блок шкалы */}
            {(isOpenScale || manualActiveStage) && (
              <Box sx={{ marginTop: '30px' }}>
                <Typography variant="h6" className={classes.categoryTitle}>
                  {criteria && criteria[1]?.name}
                </Typography>
                <Typography className={classes.sectionTitleDemonstration}>
                  {scaleTitleStr}
                </Typography>
                {!isMobile ? (
                  /* === Вариант для широких экранов: таблица === */
                  <Box className={classes.categoryBox}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                      <Table className={classes.table} size="medium" aria-label="points table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell} align="center">
                              Баллы
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Проявление
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Определение
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                              Имеет место
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scaleQuestions?.map((scaleQuestion) => (
                            <TableRow key={scaleQuestion.id}>
                              <TableCell className={classes.tableCell} align="center">
                                {scaleQuestion.points ?? '—'}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="center">
                                {scaleQuestion.demonstration ?? '—'}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="left">
                                {scaleQuestion.description ?? '—'}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={isScaleResult || manualActiveStage}
                                      checked={
                                        !!savedAnswers.find(
                                          (ans) => ans.description?.id === scaleQuestion.id
                                        )
                                      }
                                      onChange={() => toggleAnswer(Number(scaleQuestion.id))}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontFamily: 'Helvetica',
                                        fontWeight: 400,
                                        fontSize: 15,
                                        color: isScaleResult || manualActiveStage ? 'grey' : 'black',
                                      }}
                                    >
                                      Применимо
                                    </Typography>
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box className={classes.buttonGroup}>
                      {!isScaleResult && !manualActiveStage ? (
                        <CustomButton
                          onClick={async () => {
                            await fetchSavedAnswers();
                            setIsScaleResult(true);
                            setIsShowNextStageBtn(true);
                          }}
                        >
                          Закончить опрос
                        </CustomButton>
                      ) : (
                        <Box className={classes.resultContainer}>
                          <Box className={classes.resultBox}>
                            {totalPoints}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : (
                  /* === Вариант для мобильных (ширина <= 800px): вертикальные карточки === */
                  <Box>
                    {scaleQuestions?.map((sq) => (
                      <Box key={sq.id} className={classes.verticalCard}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography sx={{ fontWeight: 600 }} className={classes.labelRow}>Баллы: </Typography>
                          <Typography sx={{ marginBottom: '8px', marginLeft: '10px' }}>
                            {sq.points ?? '—'}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography sx={{ fontWeight: 600 }} className={classes.labelRow}>Проявление: </Typography>
                          <Typography sx={{ marginBottom: '8px', marginLeft: '10px' }}>
                            {sq.demonstration ?? '—'}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 600 }} className={classes.labelRow}>Определение:</Typography>
                        <Typography sx={{ marginBottom: '8px' }}>
                          {sq.description ?? '—'}
                        </Typography>
                        <Box sx={{
                          display: 'flex !important',
                          justifyContent: 'center !important',
                        }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={isScaleResult || manualActiveStage}
                                checked={
                                  !!savedAnswers.find(
                                    (ans) => ans.description?.id === sq.id
                                  )
                                }
                                onChange={() => toggleAnswer(Number(sq.id))}
                                color="primary"
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  fontFamily: 'Helvetica',
                                  fontWeight: 400,
                                  fontSize: 15,
                                  color: isScaleResult || manualActiveStage ? 'grey' : 'black',
                                }}
                              >
                                Применимо
                              </Typography>
                            }
                          />
                        </Box>
                      </Box>
                    ))}
                    <Box className={classes.buttonGroup}>
                      {!isScaleResult && !manualActiveStage ? (
                        <CustomButton
                          onClick={async () => {
                            await fetchSavedAnswers();
                            setIsScaleResult(true);
                            setIsShowNextStageBtn(true);
                          }}>
                          Закончить опрос
                        </CustomButton>
                      ) : (
                        <Box className={classes.resultContainer}>
                          <Box className={classes.resultBox}>
                            {totalPoints}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {isShowNextStageBtn || manualActiveStage ?
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CustomButton onClick={goToNextStage}>
                  Следующий этап
                </CustomButton>
              </Box> :
              null}
          </Box>
        }
      </Box>
    </MainLayout>
  );
};

export default QuestionnairePage;
