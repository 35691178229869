import React, { useEffect, useRef, useState } from 'react';
import {
  DataTable,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  ToastNotification,
  Loading,
  Select,
  SelectItem
} from '@carbon/react';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@mui/material/FormControlLabel';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { getAnalytics } from "../../api/chatApi/chatApi";
import { makeStyles } from "@material-ui/core/styles";
import CustomTablePagination from '../CustomPagination';
import { Checkbox, FormGroup } from '@material-ui/core';
import AnalyticMobileElement from '../../components/ratingMobileElement/AnalyticMobileElement';

interface AnalyticTableInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
}

interface analyticInterface {
  id?: number
  medicine_name: string
  count: number
  organization_name: string
  tasc_name: string
  trainer_name: string
  typeofdifficultlevel_name: string
  universitylevel_name: string
  student_speciality?: string
  index?: number
}

const useStyles = makeStyles((theme: any) => ({
  requiredWarning: {
    '& .cds--toast-notification__details': {
      display: 'flex !important',
      alignItems: 'center !important',
      height: 50
    },
  },
  championTablePageContainer: {
    background: '#F4F4F4',
    // height: '100vh',
    borderRadius: 4,
    marginBottom: 10,
    '@media (max-width: 800px)': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
  championTablePageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '98%',
    margin: '0 auto',
    // height: '80vh',
  },
  championTableFilterBlock: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    background: '#FFFFFF',
    marginRight: '1%',
    width: '100%',
    marginBottom: '1%',
    margin: '0 auto',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    // flexDirection: 'column',
    width: '100%',
    // marginBottom: 8,
    alignItems: 'center',
    '& .cds--select-input': {
      borderRadius: 4,
      // marginRight: '15px',
      width: '350px',
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15,
      alignItems: 'start',
      '& .cds--select-input': {
        width: '225px',
      },
    },
  },
  selectBlockDatePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 20,
    width: '100%',
    alignItems: 'center',
    '& .cds--select-input': {
      marginRight: '15px',
      width: '350px',
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15,
      alignItems: 'start',
      '& .cds--select-input': {
        width: '225px',
      },
    },
  },
  datePickerText: {
    whiteSpace: 'pre',
    fontSize: 16,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 15,
    '@media (max-width: 800px)': {
      alignSelf: 'baseline'
    },
  },
  championSelectElement: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '5%',
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championTable: {
    width: '100%',
    marginRight: '1%',
    background: '#FFFFFF',
  },
  customBtn: {
    borderRadius: 4,
    height: 40,
    width: 175,
    maxWidth: 225,
    background: '#0f62fe',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: 15,
    '&:hover': {
      background: '#0050e6'
    },
    '@media (max-width: 800px)': {
      width: 225,
    },
  },
  multiselectCustomClass: {
    width: 320,
    '& div.cds--list-box__menu': {
      width: 300,
      backgroundColor: '#F4F4F4'
    },
    '& .cds--select-input': {
      width: '100%',
      backgroundColor: '#F4F4F4'
    },
    '@media (max-width: 800px)': {
      '& .cds--select-input': {
        width: 185,
        maxWidth: 225
      },
    },
  },
  emptyTableText: {
    whiteSpace: 'pre',
    fontSize: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    '@media (max-width: 800px)': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },
  tableContainer: {
    width: '100%',
    overflowY: 'auto',
    marginTop: 10,
    '& .cds--data-table-content': {
      maxHeight: '85vh',
    },
    '& .tbody': {
      minHeigth: '500px'
    }
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '80%'
  },
  datePickerAndFiltersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  datePickerInput: {
    width: 150
  },
  formControlLabel: {
    minWidth: 250
  },
  btnContainer: {
    marginTop: '6%',
    width: '100%',
    textAlign: 'right'
  }
  //
}))

export default function AnalyticTable({
  countCompleteTask,
}: AnalyticTableInterface): JSX.Element {

  const style = useStyles()
  const defaultHeaders = [
    {
      key: 'medicine_name',
      header: 'Раздел медицины',
      active: true,
      secondHeader: 'medicine',
      id: 0,
    },
    {
      key: 'typeofdifficultlevel_name',
      header: 'Уровень сложности',
      active: true,
      secondHeader: 'difficultlevel',
      id: 1,
    },
    {
      key: 'organization_name',
      header: 'Организация',
      active: true,
      secondHeader: 'organization',
      id: 2
    },
    {
      key: 'student_speciality_name',
      header: 'Специальность',
      active: true,
      secondHeader: 'student_speciality_name',
      id: 3
    },
    {
      key: 'tasc_name',
      header: 'Задача',
      active: true,
      secondHeader: 'task',
      id: 4
    },
    {
      key: 'testingstage_name',
      header: 'Стадия задачи',
      active: true,
      secondHeader: 'testingstage',
      id: 5,
    },
    {
      key: 'trainer_name',
      header: 'Тренажер',
      active: true,
      secondHeader: 'trainer',
      id: 6
    },
    {
      key: 'universitylevel_name',
      header: 'Академический уровень',
      active: true,
      secondHeader: 'universitylevel',
      id: 7
    },
    {
      key: 'count',
      header: 'Количество',
      active: true,
      secondHeader: 'count',
      id: 8
    },
  ]
  const [headers, setHeaders] = useState<any[]>([])
  const [analyticList, setAnalyticList] = useState<analyticInterface[]>([])
  const [allTypes, setAlltypes] = useState([
    { id: 1, type: 'Количество задач' },
    { id: 2, type: 'Количество решенных задач' },
    { id: 3, type: 'Средний балл' },
    { id: 4, type: 'Стадия завешения задачи' },
  ])
  const [type, setType] = useState(1)
  const [headerBeforeUpdate, setHeaderBeforeUpdate] = useState([])
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [warningState, setWarningState] = useState(false)
  // const [firstRowIndex, setFirstRowIndex] = useState(0);
  // const [currentPageSize, setCurrentPageSize] = useState(10);
  const [errorState, setErrorState] = useState(false)
  const [requiredFieldsState, setRequiredFieldsState] = useState(false)
  const [page, setPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(5);

  useEffect(() => {
    if (requiredFieldsState) {
      setTimeout(() => setRequiredFieldsState(false), 3000)
    }
  }, [requiredFieldsState])

  const [body, setBody] = useState({
    start_date: '',
    stop_date: '',
    difficultlevel: true,
    medicine: true,
    organization: true,
    student_speciality: true,
    task: true,
    testingstage: true,
    trainer: true,
    universitylevel: true,
    type: type,
  })
  const [filterCheckbox, setFilterCheckbox] = useState({
    medicine: true,
    organization: true,
    task: true,
    trainer: true,
    difficultlevel: true,
    universitylevel: true,
    student_speciality: true,
    testingstage: true,
  })
  const [checkboxList, setCheckboxList] = useState(
    [
      { checkboxFilter: 'medicine', active: true, name: 'Раздел медицины', checkboxId: 0, text: 'Раздел медицины' },
      { checkboxFilter: 'difficultlevel', active: true, name: 'Уровень сложности', checkboxId: 1 },
      { checkboxFilter: 'organization', active: true, name: 'Организация', checkboxId: 2 },
      { checkboxFilter: 'student_speciality', active: true, name: 'Специализация студента', checkboxId: 3 },
      { checkboxFilter: 'task', active: true, name: 'Задача', checkboxId: 4 },
      { checkboxFilter: 'testingstage', active: true, name: 'Стадия задачи', checkboxId: 5 },
      { checkboxFilter: 'trainer', active: true, name: 'Тренажер', checkboxId: 6 },
      { checkboxFilter: 'universitylevel', active: true, name: 'Академический уровень', checkboxId: 7 },
      { checkboxFilter: 'average_score', active: true, name: 'Средний балл', checkboxId: 8 },
    ]
  )

  useEffect(() => {
    if (analyticList.length > 0) {
      sortHeadersTitleHandler()
    }
  }, [analyticList])

  function sortHeadersTitleHandler() {
    let setUniqTitleArray = new Set()
    if (analyticList) {
      analyticList.map(analytic => {
        for (let key in analytic) {
          defaultHeaders.map((header, index) => {
            if (header.key === key) {
              setUniqTitleArray.add(header)
            }
          })
        }
      })
    }
    let uniqTitleArray = Array.from(setUniqTitleArray)
    setHeaderBeforeUpdate(uniqTitleArray)
  }

  function changeFilterCheckbox(check) {
    let array = []
    let obj = {}
    let newHeaders: any[] = headers

    array = checkboxList.map((checkbox, index) => {
      if (index === check.checkboxId) {
        let status = check.active = !check.active

        if (check.active === false) {
          newHeaders = headers.filter((head, headId) => head.secondHeader !== checkbox.checkboxFilter)
        } else {
          newHeaders = [...headers, defaultHeaders[checkbox.checkboxId]]
        }

        Object.keys(filterCheckbox)
        obj = filterCheckbox
        obj = {
          ...filterCheckbox,
          [checkbox.checkboxFilter]: status !== false ? true : null
        }
        setBody({ ...body, ...filterCheckbox, [checkbox.checkboxFilter]: status !== false ? true : null })

        newHeaders.sort((a, b) => {
          return a.id - b.id
        })
        setHeaders(newHeaders)
        setFilterCheckbox(obj)
      }
      setTimeout(() => setWarningState(false), 5000)
      return checkbox
    })
    setCheckboxList(array)
  }

  async function getAnalyticsHandler() {
    try {
      if (!endTime || !startTime) {
        setRequiredFieldsState(true);
        return
      }
      setAnalyticList([])
      setIsLoading(true)
      const responseArray: analyticInterface[] = []
      await getAnalytics(body).then(response => {
        response.data.analytics.map((res: analyticInterface, index: number) => {
          responseArray.push({ ...res, id: index })
        })
      })

      sortHeadersTitleHandler()
      setAnalyticList(responseArray)
    } catch (err) {
      setErrorState(true)
      setAnalyticList([])
      console.log('при получении списка произошла ошибка', err)
    } finally {
      setTimeout(() => setErrorState(false), 5000)
      setIsLoading(false)
    }
  }


  const handleStartDateChange = (date: Date) => {
    setStartTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      start_date: res,
    })
  }

  const handleEndDateChange = (date: Date) => {
    setEndTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      stop_date: res
    })
  };

  const [isLoading, setIsLoading] = useState(false)

  function Loader() {
    if (isLoading) {
      return (
        <Loading className={'some-class'} withOverlay={false} />
      )
    } else return null
  }

  const indexOfFirstItem = page * currentPageSize;
  const indexOfLastItem = indexOfFirstItem + currentPageSize;
  const currentData = analyticList.slice(indexOfFirstItem, indexOfLastItem);

  function changeTypeHandler(event) {
    setType(event.target.value)
    setBody({ ...body, type: +event.target.value })
  }

  const [innerWidth, setInnerWidth] = useState<number | null>(null)
  const containerRef = useRef(null)

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width
  }

  useEffect(() => {
    function handleResize() {
      setInnerWidth(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return (
    <div ref={containerRef} className={style.championTablePageContainer}>
      {/* {
        warningState &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            iconDescription="После внесения изменений нажмите кнопку Сформировать"
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title="Изменение данных"
          />
        </div>
      } */}
      {requiredFieldsState && (
        <div style={{ position: "fixed", right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={true}
            lowContrast={false}
            className={style.requiredWarning}
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title=""
          ><span>Обязательные поля не заполнены</span></ToastNotification>
        </div>
      )}
      {
        errorState &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            iconDescription="Нет результатов"
            timeout={0}
            title="Нет результатов"
          />
        </div>
      }
      <div style={{ width: "98%", padding: "20px 0", margin: "0 auto" }}>
        <p style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}>
          Аналитические данные
        </p>
      </div>

      <div
        style={{
          background: '#FFFFFF',
          fontSize: '104%',
          width: '98%',
          padding: '15px 10px',
          margin: 'auto auto 12px auto',
          borderRadius: 4
        }}>
        <p>Отображение сводной информации по ситуационным задачам</p>
      </div>
      <div className={style.championTablePageContent}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={style.championTableFilterBlock}>
            <div className={style.championSelectBlock}>
              <div className={style.checkBoxContainer} >
                {checkboxList.map((check, index) => {
                  return (
                    <FormGroup>
                      <FormControlLabel className={style.formControlLabel} control={
                        <Checkbox
                          value={check.name}
                          defaultChecked
                          color="primary"
                          id={`checkbox-label-${index}`}
                          onChange={() => changeFilterCheckbox(check)}
                        />}
                        label={check.name}
                      />
                    </FormGroup>
                  )
                })}
              </div>

              <div className={style.datePickerAndFiltersContainer}>
                <div className={style.championSelectElement}>
                  <p
                    className={style.datePickerText}
                  >отображать
                  </p>
                  <Select
                    labelText=""
                    onChange={(event) => changeTypeHandler(event)}
                    className={style.multiselectCustomClass}
                  >
                    {allTypes.map((type, index) => (
                      <SelectItem
                        text={type.type}
                        value={type.id}
                        key={`${index}${type}`}
                      />
                    ))}
                  </Select>
                </div>
                <div className={style.selectBlockDatePicker}>
                  <p
                    className={style.datePickerText}
                  >за период
                  </p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      id="date-picker-inline"
                      placeholder="с"
                      value={startTime}
                      animateYearScrolling={true}
                      onChange={handleStartDateChange}
                      className={style.datePickerInput}
                    />
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      minDate={startTime}
                      id="date-picker-inline"
                      placeholder="по"
                      value={endTime === 'Invalid Date' ? startTime : endTime}
                      onChange={handleEndDateChange}
                      animateYearScrolling={true}
                      className={style.datePickerInput}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={style.btnContainer}>
                  <button
                    onClick={getAnalyticsHandler}
                    className={style.customBtn}
                  >Применить фильтры
                  </button>
                </div>

              </div>

            </div>
          </div>
        </div>

        {isLoading
          ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Loader />
          </div>
          : analyticList && analyticList.length !== 0 && innerWidth >= 600 ?
            <div className={style.tableContainer}>
              {analyticList &&
                <DataTable
                  isSortable
                  rows={currentData}
                  headers={headerBeforeUpdate}
                  style={{ width: '100%', flexGrow: 1 }}
                >
                  {({ rows, headers, getHeaderProps, getRowProps, getTableProps }) => {
                    const averageScore = type == 3 ? 'Средний балл' : 'Количество'
                    return (
                      <TableContainer title="">
                        <Table {...getTableProps()} style={{ width: '100%', borderRadius: 4 }}>
                          <TableHead style={{
                            backgroundColor: "#FFFFFF",
                            position: "sticky",
                            top: 0,
                            zIndex: 1000,
                            boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)',
                          }}>
                            <TableRow>
                              {headers.map((header, index) => {
                                const headerContent = index === headers.length - 1 ? averageScore : header.header;
                                return (
                                  <TableHeader key={header.id} {...getHeaderProps({ header })}>
                                    {headerContent}
                                  </TableHeader>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody style={{ width: '100%', flexGrow: 1 }}>
                            {rows.map(row => (
                              <TableRow {...getRowProps({ row })}
                                key={row.id}
                              >
                                {row.cells.map((cell, index) => (
                                  <TableCell
                                    key={cell.id}
                                  >
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                  }}
                </DataTable>
              }
            </div> :
            analyticList.map(type => (
              <AnalyticMobileElement ratingElement={type} />
            ))
        }
      </div>
      { allTypes?.length === 0 &&
        <div style={{ margin: '25px auto 0 auto', paddingBottom: 20 }}>
          <p className={style.emptyTableText}>Чтобы увидеть информацию о аналитических данных заполните фильтры</p>
        </div>
      }
      {!isLoading &&
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            paddingRight: 10,
            paddingBottom: 7,
            marginBottom: 10,
            backgroundColor: '#F4F4F4',
            borderRadius: 4
          }}>
          {analyticList.length > 0 &&
            <CustomTablePagination
              count={analyticList.length}
              page={page}
              rowsPerPage={currentPageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onRowsPerPageChange={(newPageSize) => {
                setCurrentPageSize(newPageSize);
                setPage(0);
              }}
            />}
        </div>
      }
    </div>
  )
}
