import { createStyles, makeStyles } from '@material-ui/core';

interface getAssessmentProfessionalStandardInterface {
  name: string,
  task_point_stage_procent: string,

}
interface ChampionMobileElement {
  ratingElement: getAssessmentProfessionalStandardInterface
}

export default function ModelMobileElement({ratingElement}: ChampionMobileElement) {

  const useStyles = makeStyles((theme) =>
    createStyles({
      ratingMobileElementContainer: {
        border: '1px solid gray',
        borderRadius: 12,
        padding: 10,
        margin: '15px auto',
        maxWidth: 1045,
        width: '100%'
      },
      titleChapter: {
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      },
      textWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 10
      },
      descriptionChapter: {
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      }
    })
  );

  const style = useStyles()

  return (
    <div className={style.ratingMobileElementContainer}>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Стадия: </p>
        <span className={style.descriptionChapter}>{ratingElement.name}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Моделируемый процент верного выполнения: </p>
        <span className={style.descriptionChapter}>{ratingElement.task_point_stage_procent}</span>
      </div>
    </div>
  )

}