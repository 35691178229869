import { createStyles, makeStyles } from '@material-ui/core';

interface getAssessmentProfessionalStandardInterface {
  id: number,
  kind: string,
  serverresponseobject: number,
  category: null | number
  type_competence: number,
  values: number,
  competence_name: string
}
interface ChampionMobileElement {
  ratingElement: getAssessmentProfessionalStandardInterface
}

export default function ChampionMobileElement({ratingElement}: ChampionMobileElement) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      ratingMobileElementContainer: {
        border: '1px solid gray',
        borderRadius: 12,
        padding: 10,
        margin: '15px auto',
        maxWidth: 1045,
        width: '100%'
      },
      titleChapter: {
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      },
      textWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 10
      },
      descriptionChapter: {
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginTop: 2
      }
    })
  );
  const accordance = ratingElement.values <= ratingElement.serverresponseobject ? 'соответствует' : 'не соответствует'
  const style = useStyles()

  return (
    <div className={style.ratingMobileElementContainer}>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Специальность: </p>
        <span className={style.descriptionChapter}>{ratingElement.kind}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Категория: </p>
        <span className={style.descriptionChapter}>{ratingElement.category}</span>
      </div>
      <div style={{margin: '4px auto'}}>
        <p className={style.titleChapter}>Наименование компетенции: </p>
        <span className={style.descriptionChapter}>{ratingElement.competence_name}</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Процент освоения: </p>
        <span className={style.descriptionChapter}>{ratingElement.serverresponseobject} %</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Необходимое значение %: </p>
        <span className={style.descriptionChapter}>{ratingElement.values} %</span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.titleChapter}>Соответствие: </p>
        <span className={style.descriptionChapter}>{accordance}</span>
      </div>
    </div>
  )

}