import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AsyncState } from 'react-use/lib/useAsync'
import {
  Diagnosis_ReqData,
  getTypeofPatientStatus,
  PatientStatusCode,
  pushPreDiagnosis,
  getTestingstageTaskOnTask,
  setRheumatologyDiagnoses
} from '../../api/chatApi/chatApi'
import { useLocation } from 'react-router-dom';
import RatingDialog from '../../components/Chat/RatingDialog'
import CustomButton from '../../components/common/CustomButton'
import CustomHeader1 from '../../components/common/CustomHeader1'
import Slot from '../../components/common/Slots/Slot'
import SlotContainerMasonry from '../../components/common/Slots/SlotContainerMasonry'
import TooltipDialog from '../../components/common/TooltipDialog'
import MainLayout from '../../components/Layout/MainLayout'
import DiagnosisFields, {
  DiagnosisFieldData
} from '../../components/PageBlocks/Diagnosis/MainDiagnosisField'
import PathophysiologicalComponent from '../../components/PageBlocks/PreDiagnosis/PathophysiologicalModel'
import PreDiagnosisTooltip from '../../components/Tooltips/PreDiagnosisTooltip'
import useDiagnosisState from '../../hooks/diagnosis/useDiagnosisState'
import useDiagnosisTypes from '../../hooks/diagnosis/useDiagnosisTypes'
import useTooltipState from '../../hooks/useTooltipState'
import { RootState } from '../../store'
import { SagaActionType } from '../../store/sagas/sagaActions'
import { mapAsyncState, RoutePath } from '../../types'
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu'
import CompetingDiagnosis from '../../components/PageBlocks/Diagnosis/CompetingDiagnosisField'
import ComplicationDiagnosisField from '../../components/PageBlocks/Diagnosis/ComplicationDiagnosisField'
import AccompanyingDiagnosisField from '../../components/PageBlocks/Diagnosis/AccompanyingDiagnosisField'
import MainDiagnosisField from '../../components/PageBlocks/Diagnosis/MainDiagnosisField'
import chatApi from '../../api/chatApi'
import TooltipAccordion from '../../components/TooltipAccordion'
import { usePointProcentStore } from './store'


const PreDiagnosisPage: React.FunctionComponent = () => {
  const asyncStateDiagnosisTypes = useDiagnosisTypes()
  const diagnosisTypes = asyncStateDiagnosisTypes?.value
  const [toggle, setToggle] = useState(false)
  const [status, setStatus] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)
  const [diagnosis, setDiagnosis] = useState<any>([])
  const [currentInputId, setCurrentInputId] = useState<number>()
  const [tooltipState, openTooltip] = useTooltipState()
  const [isButtonActive, setIsButtonActive] = useState(false);
  const getPointProcent = usePointProcentStore(state => state.getPoints)
  const dispatch = useDispatch()
  const history = useHistory()
  const [stages, setStages] = useState([]);
  const { routhPathCode, activeStage } = useNextStagePath(stages);
  const [manualActiveStage, setManualActiveStage] = useState(false)

  const setDiagnoses = async () => {
    try {
      let diagnosisArr = []
      diagnosis.forEach(diagnosis => diagnosisArr.push(diagnosis.id));
      await setRheumatologyDiagnoses(diagnosisArr)
    } catch (error) {
      console.log(error)
    }
  }

  const {
    condition,
    onChageCondition,
    isFormValid,
    onChangeDiagnosis,
    pushDiagnosis,
    pushDiagnosisAsyncState
  } = useDiagnosisState(true)

  const preDiagnosisForm = useSelector<
    RootState,
    AsyncState<Diagnosis_ReqData> | undefined
  >(state =>
    mapAsyncState(
      s => s.find(it => it.isProvisional)!!,
      state.chat.diagnosisForms
    )
  )


  useEffect(() => {
    dispatch({ type: SagaActionType.START_LOAD_DIAGNOSIS_FOMRMS })
  }, [dispatch])

  const location = useLocation();

  // Функция для перехода на следующий этап
  async function goToNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);

    if (!currentStageObj) {
      setHistoryStage(6);
    }

    setHistoryStage(currentStageObj?.activeStage);
    const currentActiveStage = currentStageObj?.activeStage;

    const sortedNextStages = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage);

    if (sortedNextStages.length === 0) {
      console.warn('Нет доступных следующих стадий.');
      return;
    }

    let nextIndex = 0;

    if (sortedNextStages[nextIndex].code === 'conclusion') {
      nextIndex += 1;

      if (nextIndex >= sortedNextStages.length) {
        nextIndex = 0;
      }
    }

    const finalNextStage = sortedNextStages[nextIndex];

    if (finalNextStage.legend_id) {
      history.push({
        pathname: RoutePath.legend,
        state: {
          legendId: finalNextStage.legend_id
        }
      });
    } else {
      history.push(finalNextStage.routePath);
    }
  }

  async function getHistoryStage() {
    try {
      const res = await chatApi.getTestingStageHistory()
      const result = res.result.find((el) => el.activeStageId === 6)
      if (result && (!stages || stages.length === 0)) {
        setManualActiveStage(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function setHistoryStage(testingStage) {
    try {
      const res = await chatApi.setTestingStageHistory(testingStage)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (stages && stages.length > 0) {
      getHistoryStage()
    }
  }, [stages])


  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  function useNextStagePath(stages) {
    const location = useLocation();
    const currentPath = location.pathname;

    return useMemo(() => {
      if (!stages || stages.length === 0) {
        return { routePath: '', routhPathCode: '' };
      }
      const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
      const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);

      if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
        return {
          routePath: sortedStages[currentIndex + 1].routePath,
          routhPathCode: sortedStages[currentIndex + 1].routhPathCode,
          activeStage: sortedStages[currentIndex + 1].activeStage
        };
      }

      return { routePath: '', routhPathCode: '', activeStage: '' };
    }, [stages, currentPath]);
  }


  const onClickSendDiagnosis = () => {
    if (stages && stages.length > 0) {
      pushDiagnosis().then(() => {
        if (routhPathCode) {
          if (routhPathCode === 'research') {
            history.replace(RoutePath.researchInitial);
          } else {
            history.replace(RoutePath[routhPathCode])
          }
        } else {
          console.error('Ошибка при переходе на следующий этап');
        }
      });
    } else {
      pushDiagnosis().then(() => history.push(RoutePath.researchInitial))
    }

  }

  const [currTaskState, setCurrTaskState] = useState<number | null>(null)
  useEffect(() => {
    getDifficultLevel()
    // setAsyncTaskStage()
  }, [])

  async function getDifficultLevel() {
    try {
      const response = await chatApi.unfinishedTask()
      setCurrTaskState(response?.result?.task.typeOfDifficultLevelId)
    } catch (err) {
      console.log(`При получении уровня сложности произошла ошибка: ${err}`)
    }
  }

  const type8 = () => {
    if (condition === 'Light') {
      return 1
    }
    if (condition === 'Normal') {
      return 2
    }
    if (condition === 'Hard') {
      return 3
    }
    if (condition === 'Fatal') {
      return 4
    }
  }

  const pushPreDiagnos = async () => {
    const obj: any = {};
    diagnosis.map((el, index) => {
      obj[`typeofinformationlevelid_${el.input}`] = el.input === 4 ? el.id : [el.id];
      obj.typeofinformationlevelid_8 = type8()
    });

    await pushPreDiagnosis(obj)
      .catch(err => console.log(err))
  }


  useEffect(() => {
    const getDialogStatus = async () => {
      try {
        const response = await chatApi.getSettingsPiloting();
        const statusValue = response.result[0].value;
        setStatus(statusValue === 'true');
      } catch (error) {
        console.log(error)
      }
    };

    getDialogStatus();
  }, []);


  function showRatingDialog() {
    if (stages) {
      goToNextStage()
    } else if (!manualActiveStage) {
      setHistoryStage(5)
      history.push(RoutePath.research)
    } else {
      setHistoryStage(5)
      if (status) {
        pushPreDiagnos()
        setOpen(true)
      } else {
        onClickSendDiagnosis()
        // setAsyncTaskStage()
      }
    }
  }

  function hideRatingDialog() {
    setHistoryStage(5)
    onClickSendDiagnosis()
    setOpen(false)
    // increase()
    // setAsyncTaskStage()
  }


  function onFocusHandler(id: number) {
    setCurrentInputId(id)
  }


  if (diagnosisTypes) {
    const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[1]?.id)
  }



  useEffect(() => {
    const setupStatus = async () => {
      try {
        const statusNumber = await getTypeofPatientStatus();
        const result = statusNumber.data.typeofpatientstatus_id;
        let condition: PatientStatusCode;

        if (result === 1) {
          condition = PatientStatusCode.Light;
        } else if (result === 2) {
          condition = PatientStatusCode.Normal;
        } else if (result === 3) {
          condition = PatientStatusCode.Hard;
        } else if (result === 4) {
          condition = PatientStatusCode.Fatal;
        } else {
          condition = PatientStatusCode.Light;
        }
        onChageCondition(condition);

      } catch (error) {
        console.log(error);
      }
    };

    setupStatus();
  }, []);

  const [diagnosisCodeValue, setDiagnosisCodeValue] = useState('');

  const handleDiagnosisCodeChange = (value) => {
    setDiagnosisCodeValue(value);
  };


  useEffect(() => {
    getPointProcent()
  }, [])

  const taskName = '5'

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
  );

  function getDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);

    if (isMobile) {
      return "mobile";
    } else {
      return "desktop";
    }
  }

  function isDeviceAndOrientation() {
    return !(getDeviceType() !== 'desktop' && orientation === 'landscape');
  }

  useEffect(() => {

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    };

    const handleOrientationChange = () => {
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <>
      {diagnosisTypes ? (
        <MainLayout
          desktopHeader={<NewChatWizardTopMenu title={'Предварительный диагноз'} />}
          title='Предварительный диагноз'
          onClickTooltip={openTooltip}
        >

          {open ? <RatingDialog open={open} onSubmit={hideRatingDialog} /> : null}
          <TooltipDialog {...tooltipState}>
            <PreDiagnosisTooltip />
          </TooltipDialog>
          <TooltipAccordion taskName={taskName} />
          {!isDeviceAndOrientation() && orientation === 'landscape' ?
            <div style={{background: '#1976D2', borderRadius: 8, padding: 10, margin: 20}}>
              <p style={{color: 'white'}}>Для того чтобы продолжить задачу переверните телефон вертикально</p>
            </div>
            :
            <div style={{ paddingBottom: `25px` }}>
              {!manualActiveStage ? (
                <React.Fragment>
                  <SlotContainerMasonry>
                    <Slot title={diagnosisTypes && diagnosisTypes[0]?.description} color='primary'>
                      <MainDiagnosisField
                        diagnosis={[]}
                        maxLines={1}
                        level={currTaskState}
                        toggle={setToggle}
                        onChange={fields => diagnosisTypes && onChangeDiagnosis(diagnosisTypes[0]?.id, fields)}
                        onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[0].id)}
                        idInput={diagnosisTypes && diagnosisTypes[0]?.id}
                        currentInputId={currentInputId}
                        setDiagnosis={setDiagnosis}
                        items={diagnosis}
                        setIsButtonActive={setIsButtonActive}
                        diagnosisCodeValue={diagnosisCodeValue}
                        onDiagnosisCodeChange={handleDiagnosisCodeChange}
                      />
                    </Slot>
                    {currTaskState !== 2 &&
                      <Slot title={diagnosisTypes && diagnosisTypes[1]?.description} color='primary'>
                        <CompetingDiagnosis
                          diagnosis={[]}
                          maxLines={1}
                          level={currTaskState}
                          onChange={fields => {
                            if (diagnosisTypes) {
                              const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[1]?.id);
                              const allFields = [...currentFields, ...fields];
                              onChangeDiagnosis(diagnosisTypes[1]?.id, allFields);
                            }
                          }}
                          onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[1].id)}
                          idInput={diagnosisTypes && diagnosisTypes[1]?.id}
                          currentInputId={currentInputId}
                          setDiagnosis={setDiagnosis}
                          items={diagnosis}
                        />
                      </Slot>
                    }
                    {currTaskState !== 2 &&
                      <Slot title={diagnosisTypes && diagnosisTypes[2]?.description} color='primary'>
                        <ComplicationDiagnosisField
                          diagnosis={[]}
                          maxLines={1}
                          level={currTaskState}
                          onChange={fields => {
                            if (diagnosisTypes) {
                              const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[2]?.id);
                              const allFields = [...currentFields, ...fields];
                              onChangeDiagnosis(diagnosisTypes[2]?.id, allFields);
                            }
                          }}
                          onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[2].id)}
                          idInput={diagnosisTypes && diagnosisTypes[2]?.id}
                          currentInputId={currentInputId}
                          setDiagnosis={setDiagnosis}
                          items={diagnosis}
                        />
                      </Slot>
                    }
                    {currTaskState !== 2 &&
                      <Slot title={diagnosisTypes && diagnosisTypes[3]?.description} color='primary'>
                        <AccompanyingDiagnosisField
                          diagnosis={[]}
                          maxLines={1}
                          level={currTaskState}
                          onChange={fields => {
                            if (diagnosisTypes) {
                              const currentFields = diagnosis.filter(item => item.input === diagnosisTypes[3]?.id);
                              const allFields = [...currentFields, ...fields];
                              onChangeDiagnosis(diagnosisTypes[3]?.id, allFields);
                            }
                          }}
                          onClickInput={() => diagnosisTypes && onFocusHandler(diagnosisTypes[3].id)}
                          idInput={diagnosisTypes && diagnosisTypes[3]?.id}
                          currentInputId={currentInputId}
                          setDiagnosis={setDiagnosis}
                          items={diagnosis}
                        />
                      </Slot>
                    }
                    <div>
                      {/* <PatientConditionSlot
                      value={condition}
                      onChange={onChageCondition}
                    /> */}

                      {/*<PathophysiologicalComponent />*/}
                    </div>
                  </SlotContainerMasonry>
                  {currTaskState === 3 ? (<CustomButton
                    onClick={showRatingDialog}
                    disabled={toggle ? false : true}
                  >
                    Завершить шаг
                  </CustomButton>) : (
                    <CustomButton
                      onClick={() => {
                        showRatingDialog()
                        setDiagnoses()
                      }}
                      disabled={toggle ? false : true}
                      loading={pushDiagnosisAsyncState?.loading}
                    >
                      Завершить шаг
                    </CustomButton>
                  )
                  }



                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CustomHeader1>Предварительный диагноз уже отправлен</CustomHeader1>
                  <SlotContainerMasonry>
                    {preDiagnosisForm?.value &&
                      diagnosisTypes &&
                      diagnosisTypes.map((it, indx) => {
                        const fieldData = preDiagnosisForm.value.diagnosis
                          .filter(di => {
                            return (di.typeOfInformationLevel || -1) === it.id
                          })
                          .map(di => {
                            return {
                              key: new Date().getTime(),
                              diagnosis: di.name,
                              diagnosisCode: di.code
                            } as DiagnosisFieldData
                          })
                        return (
                          fieldData.length > 0 && (
                            <Slot key={indx} title={it.description} color='primary'>
                              <DiagnosisFields
                                readOnly
                                diagnosis={fieldData}
                                onChange={fields => onChangeDiagnosis(it.id, fields)}
                                onClickInput={() => onFocusHandler(it.id)}
                                idInput={it.id}
                                currentInputId={currentInputId}
                                setDiagnosis={setDiagnosis}
                                items={diagnosis}
                              />
                            </Slot>
                          )
                        )
                      })}
                    {preDiagnosisForm?.value && (
                      <div>
                        {/* <PatientConditionSlot
                        value={conditionIdConditionCode.get(
                          preDiagnosisForm.value.typeOfPatientStatusId
                        )}
                        onChange={onChageCondition}
                      /> */}

                        <PathophysiologicalComponent />
                      </div>
                    )}
                  </SlotContainerMasonry>
                  <div style={{ paddingBottom: "30px" }}>
                    <CustomButton
                      onClick={showRatingDialog}>
                      Следующий этап
                    </CustomButton>
                  </div>
                </React.Fragment>
              )}
            </div>
          }

        </MainLayout>
      ) : (
        <p>Загрузка данных...</p>
      )}
    </>
  )
}

export default PreDiagnosisPage
