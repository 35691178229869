import { usePointProcentStore, useSpec } from './../../pages/chat/store/index';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import chatApi from '../../api/chatApi';
import { SagaActionType } from '../../store/sagas/sagaActions';

type Hook = () => { show: () => void; close: () => void; open: boolean; onSubmit: () => Promise<void> };

const useFinishTaskDialogState: Hook = () => {
  const deleteDataSpec = useSpec((state: any) => state.deleteDataSpec)
  const deleteDataCheck = useSpec((state: any) => state.deleteDataCheck)
  const deleteDatas = usePointProcentStore((state: any) => state.deleteData)
  const snackContext = useSnackbar();
  const dispatch = useDispatch();
  const [finishDialogShowed, setFinishDialogShowed] = useState<boolean>(false);

  const removeAllImagePointsView = () => {
    const keys = Object.keys(localStorage);
    const imagePointsViewKeys = keys.filter(key => key.startsWith('imagePointsView'));
    imagePointsViewKeys.forEach(key => localStorage.removeItem(key));
  };

  const handleClickFinishTask = useCallback(() => {
    localStorage.setItem('idChamp', '');
    return chatApi
      .finishTask()
      .then(() => {
        localStorage.removeItem('conditionType');
        localStorage.removeItem('numberOfCondition');
        localStorage.removeItem('newResult');
        localStorage.removeItem('surgeons');
        localStorage.removeItem('surgeonList');
        localStorage.removeItem('surveyResult');
        localStorage.removeItem('isChampCompleted');
        localStorage.removeItem('isShowConclusion');
        removeAllImagePointsView()
        deleteDatas()
        deleteDataSpec()
        deleteDataCheck()
        dispatch({ type: SagaActionType.FINISH_TASK });
      })
      .catch((err: Error) => {
        snackContext.enqueueSnackbar(err.message, {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        });
      })
      .finally(() => setFinishDialogShowed(false));
  }, [dispatch, snackContext]);

  return {
    show: () => setFinishDialogShowed(true),
    close: () => setFinishDialogShowed(false),
    open: finishDialogShowed,
    onSubmit: handleClickFinishTask,
  };
};

export default useFinishTaskDialogState;
